.theme-form {
    padding-top: 30px;
    //padding-left: 20px;

    .topic-form-padding-0 {
        padding: 0;
    }

    .save-button {
        display: flex;
        justify-content: flex-end;
    }

    .buttons-section {
        display: flex;
        justify-content: center;
        
        .button {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            border: 1px solid transparent;
            font-size: 10px;
            border-radius: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 150px;
            height: 45px;
            margin-top: 10px;
        }

        .edit-button {
            background-color: #2A2F7A;
            margin-right: 25px;
        }

        .remove-button {
            background-color: red;
        }
    }
}

.theme-form-modal {
    width: 520px !important;
    margin-left: 35% !important;
    margin-top: 15% !important;
}