.qualification-review-form {
    display: block;

    .form {
        margin-bottom: 0;
        width: 100%;
        border: 0;
        flex: 1 1 auto;
        min-height: 1px;

        .buttons {
            display: flex;
            justify-content: space-between;

            .close {
                font-weight: 700;
                text-transform: uppercase;
                padding: 0.5rem;
                border: 1px solid transparent;
                font-size: 10px;
                border-radius: 10px;
                line-height: 24px;
                letter-spacing: 1px;
                width: 100px;
                height: 45px;
                margin-top: 10px;
                padding: 0.5rem;
                color: white;
                border: 1px white solid;
                background-color: gray;

                i {
                    color: white;
                    font-size: 23pt;
                }
            }
        }
    }

    .update-review {
        display: flex;
        justify-content: space-between;
        padding-top: 65px;

        button {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            border: 1px solid transparent;
            background-color: #2A2F7A;
            font-size: 10px;
            border-radius: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 100%;
            height: 45px;
            margin-top: 10px;

            i {
                color: white;
                font-size: 23pt;
            }
        }
    }
}