.particular-experiences-menu {
    background-color: #fbfcfe;
    padding-top: 70px;
    display: block;
    margin-bottom: 60px;
    
    .particular-experiences-menu-title {
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 1.5rem;
        margin-left: 25px;
    }

    .card-particular-experience {
        margin-left: 20px;
        margin-right: 0;
        width: 100%;
        margin-bottom: 20px;

        .card-body {
            padding: 0;
            margin-right: 0.25rem;
            width: 100%;
            overflow: hidden;
            border-radius: 0.3rem;
            border: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
            margin-bottom: 20px;

            .card-user {
                margin-left: 1.5rem;
                margin-bottom: 0;
                width: 100%;
                justify-content: center;
                display: flex;


                

                a {
                    text-decoration: none;
                }

                .card-user-section {
                    display: flex;

                    .card-user-username {
                        text-align: center;
                        font-weight: 700;
                        margin-top: 1rem;
                        margin-bottom: 0.25rem;
                        font-size: 16px;
                        line-height: 1.4;
                        color: #000;
                    }

                    .ambassador {
                        margin-top: 10px;
                        margin-left: 5px;
                        width: 40px;
                        height: 40px;
                    }
                }

                .card-user-timezone {
                    justify-content: flex-start;
                    display: flex;
                    text-align: center;
                    margin-top: 0.5rem; 

                    .card-user-timezone-flag {
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                    }

                    .card-user-timezone-country {
                        margin-left: 0.5rem;

                        .country {
                            color: #adb5bd;
                            margin-bottom: 0.25rem;
                            margin-top: 0;
                            font-size: 12px;
                        }

                        .timezone {
                            font-weight: 700;
                            margin-top: 0;
                            font-size: 12px;
                            color: #111;
                        }
                    }
                }

                .card-user-profile-image {
                    text-align: center;
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                    display: block;

                    img {
                        width: 90px;
                        height: 90px;
                        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
                        border-radius: 50%;
                    }
                }

                .card-user-relations {
                    justify-content: center;
                    display: flex;

                    .card-user-relations-details {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        color: #222;
                        
                        .title {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 28px;
                        }

                        .number {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 28px;
                        }
                    }

                    .followers {
                        margin-right: 1rem;
                    }
                }
            }

            .card-particular-experience-details {
                padding-top: 1rem;
                padding-left: 0.5rem;
                padding-right: 0.25rem;
                padding-bottom: 0;
                width: 100%;
                float: left ;
                min-height: 1px;

                .tags {
                    .tag {
                        font-weight: 700;
                        text-transform: uppercase;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        margin-bottom: 0.25rem;
                        margin-right: 0.25rem;
                        display: inline-block;
                        border-radius: 0.3rem;
                        font-size: 10px;
                        line-height: 32px;
                        letter-spacing: 0.6px;
                    }

                    .particular-experience-tag {
                        background-color: #1E74FD;
                        text-align: center;
                        height: 35px;
                        padding-top: 2px;
                        color: #fff;
                    }
                }

                .qualification {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 0.5rem;
                    padding-top: 5px;

                    .stars {
                        display: flex;
                        height: 12px;
                        text-align: left;
                        margin-right: 0.5rem;
                        margin-top: 0.25rem;

                        .star {
                            width: 12px;
                        }
                    }

                    .reviews {
                        font-weight: 600;
                        color: #adb5bd;
                        font-size: 12px;

                        p:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .item {
                    font-weight: 700;
                    color: #212529;
                    margin-left: 0;
                    display: inline-block;
                    font-size: 12px;
                }

                .dot {
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    display: inline-block;
                    line-height: 25px !important;
                    text-align: center;
                    background-color: #eee !important;
                }

                .about-me-particular-experience {
                    width: 100%;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    background: #fff;
                    font-weight: 500;
                    margin-top: 1rem;
                    margin-right: 0.5rem;
                    font-size: 14px;
                    line-height: 28px;
                }

                .free-class {
                    font-weight: 700;
                    color: #212529;
                    margin-top: 1rem;
                    margin-left: 0;
                    display: inline-block;
                    font-size: 12px;
                }

                .price {
                    text-align: right;

                    .button {
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 0.5rem;
                        margin: 0.5rem;
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                        cursor: pointer;
                        user-select: none;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        border-radius: 30px;
                        font-size: 10px;
                        line-height: 24px;
                        letter-spacing: 1px;
                        background-color: #2A2F7A;
                    }
                }
            }
        }

        .no-data-to-show {
            margin-top: 7px;
            padding-top: 10px;
            font-weight: 400;
            font-size: 15pt;
            height: 60px;
            text-align: center;
          }
    }
}

@media screen and (max-width: 768px) {
    .particular-experiences-menu {
        padding-top: 0px;
    }
}