.header {
    width: 100%;
    margin-bottom: 1rem;

    .container {
        margin-bottom: 1rem;
        max-width: 100%;
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;

        .gradiant-bottom {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) -11%, rgba(0, 0, 0, 0.9) 100%);
            width: 100%;
            height: 40px
        }

        .cover-image-container {
            display: flex;
            justify-content: center;
            height: 200px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) -11%, rgba(0, 0, 0, 0.9) 100%);
            border-radius: 0.3rem;
            
            .cover-image {
                //width: 100%;
                //height: 200px;
                padding-bottom: 10px;
                padding-top: 10px;
            }
        }
        

        .teacher-information {
            padding-left: 3rem;
            padding-right: 1.5rem;
            width: 100%;
            display: block;
            min-height: 1px;
            padding: 1.25rem;

            .header-user {
                display: block;

                .card-teacher-photo {
                    background-position: top 30px left;
                    width: 200px;
                    background-repeat: no-repeat;
                    padding: 0.5rem;

                    figure {
                        margin-left: 12px;
                        width: 155px;
                        overflow: hidden;
                        margin-top: 25px;
                    }

                    .teacher-photo {
                        width: 150px;
                        height: 150px;
                        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
                        float: right;
                        border-radius: 50%;
                    }
                }
            
                .relations-teacher-profile {
                    display: flex;

                    .ml-4, .mx-4 {
                        margin-left: 1.5rem;
                    }

                    .mr-3, .mx-3 {
                        margin-right: 1rem;
                    }

                    .details {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        color: #222;
                        text-decoration: none;
                        
                        .relation-quantity {
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 28px;
                        }

                        .relation-title {
                            font-weight: 600;
                            font-size: 12px;
                            line-height: 28px;
                        }
                    }
                }
            }

            .username {
                font-weight: 700 !important;
                margin-top: 1rem !important;
                margin-bottom: 0.25rem !important;
                font-size: 18px !important;
            }

            .ambassador {
                margin-top: 10px;
                margin-left: 15px;
                width: 40px;
                height: 40px;
            }

            .country {
                margin-left: 1rem;
                margin-top: 0.5rem;

                .flag {
                    border-radius: 50% !important;
                    width: 35px;
                    height: 35px;
                }
            }

            .services-details {
                padding-top: 10px;
                line-height: 1.75;
                font-size: 13.6px;

                ul {
                    padding: 0;
                }

                ul:last-child {
                    margin-bottom: 0;
                }

                li {
                    list-style: none;
                }

                a {
                    color: black;
                    text-decoration: none;
                }

                .pr-2 {
                    padding-right: 0.5rem;
                }

                .more-informarion {
                    font-weight: 700;
                    margin-top: 1.5rem;

                    button {
                        border: 0;
                        background-color: transparent;
                        font-weight: 700;
                        color: #222;
                    }
                }

                .show-ratings {
                    border: 0;
                    background: transparent;
                    padding-left: 0;
                    padding-top: 10px;
    
                    .qualification {
                        display: flex;
                        justify-content: flex-start;
                        
                        i {
                            padding-right: 0.5rem;
                            padding-top: 5px 
                        }

                        .stars {
                            display: flex;
                            height: 15px;
                            text-align: left;
                            margin-right: 0.5rem;
                            margin-top: 0.25rem;
    
                            .star {
                                width: 15px;
                            }
                        }
    
                        .reviews {
                            font-weight: 600;
                            color: #adb5bd;
                            font-size: 12px;
                            line-height: 1.4;
                            margin-bottom: 0;
                            margin-top: 0.25rem;
    
                            p:last-child {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

            .teacher-profile-buttons {
                justify-content: center;
                display: flex;

                .teacher-profile-buttons-send-message {
                    padding-right: 1.5rem;
                    padding-top: 0.5rem;

                    a {
                        text-decoration: none;

                        .send-message-image {
                            font-size: 48px;
                            color: #2A2F7A;
                        }
                    }
                }
                
                .teacher-profile-buttons-relation {
                    padding-top: 0.25rem;
                    padding-right: 1.5rem;

                    .relation-button {
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 0.5rem;
                        margin-top: 0.5rem;
                        margin-left: 0.25rem;
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                        cursor: pointer;
                        user-select: none;
                        border:0;
                        border-radius: 30px;
                        font-size: 10px;
                        line-height: 24px;
                        letter-spacing: 1px;
                        width: 150px;
                        background-color: #2A2F7A;
                    }
                }
                
                .teacher-profile-buttons-donations {
                    padding-right: 3.5rem;

                    a {
                        text-decoration: none;

                        .donations-image {
                            font-size: 48px;
                            color: forestgreen;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .header-user {
        display: flex !important;

        .relations-teacher-profile {
            margin-top: 35px;
        }
    }
}
