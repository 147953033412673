.view-content {
    .content-files {
        margin-top: 10px;
        margin-left: 20px;
        background-color: #fff;
        max-height: 500px;
        width: 100%;
        
        .content-files-header {
            background-color: #2A2F7A;
            padding: 1.5rem;
            width: 100%;
            display: flex;
            border-radius: 0.3rem;
            border: 0 !important;
        }

        .content-files-details {
            border-left: 1px solid #d1d7dc;
            border-right: 1px solid #d1d7dc;
            padding: 1.1rem;
            border-bottom: 1px solid #d1d7dc;
            border-radius: 5px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                max-width: none;

                .item {
                    color: #1c1d1f;
                    padding: 0.5rem 0;
                    display: flex;
                    align-items: flex-start;
                    width: 100%;
                    height: auto;
                    text-align: left;
                    letter-spacing: normal;
                    white-space: normal;
                    
                    button {
                        color: #1c1d1f;
                        text-decoration: none;
                        border: 0;
                        background-color: transparent;

                        i {
                            margin-right: 1rem;
                            font-size: 20px;
                        }
                    }
                    
                    .item-title-content {
                        max-width: 180px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .notes {
                        padding-left: 5px;
                        font-weight: 600;
                        cursor: pointer;
                        padding-top: 0px;
                        border: 0;
                        background-color: transparent;
                        font-size: 13px;
                    }

                    .separator {
                        flex: 1 1 0%;
                    }

                    span {
                        font-size: 13px;
                    }

                    .download-file {
                        color: #1c1d1f;
                        padding-left: 1rem;
                        height: auto;
                        text-decoration: none;
                        cursor: pointer;

                        i {
                            margin-right: 1rem;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}