.channels {
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
    border-radius: 0.3rem;
    border: 0;
    margin-bottom: 50px;

    .title {
        text-align: center;
        font-weight: 400;
        display: block;
        font-size: 25px;
    }

    .link {
        font-weight: 400;
        display: block;
        font-size: 25px;
        width: 100%;
        margin-top: 30px;
        text-align: center;

        a {
            color: #1E74FD;
            text-decoration: none;
            outline: none;
        }
    }
}
