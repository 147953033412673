.send-messages {
    background-color: #fbfcfe;
    padding-top: 70px;
    display: block;
    margin: 0px 20px 20px;
    //margin-bottom: 60px;
    width: 100%;

    .send-messages-current-user-section {
        background-color: #2A2F7A;
        width: 100%;
        display: flex ;
        border-radius: 0.3rem;
        border: 0;
        height: 70px;
        padding-top: 4px;

        .send-messages-current-user-section-user-photo {
            margin-top: 0.25rem;
            margin-left: 1.25rem;

            img {
                top: 0;
                left: 0;
                border-radius: 50%;
                width: 55px;
                height: 55px;
                border-radius: 50%;
            }
        }

        .send-messages-current-user-section-username {
            padding-left: 10px;
            color: #fff;
            font-weight: 700;
            padding-top: 18px;
            font-size: 18px
        }
    }

    .send-messages-recipients {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 0px;
    }

    .p-r-50 {
        padding-right: 50px;
    }
}