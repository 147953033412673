.content-item {
    padding: 0;
    margin-bottom: 1.5rem;
    margin-right: 0.25rem;
    overflow: hidden;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    width: 300px;

    .cover-image {
        overflow: hidden;
        margin-bottom: 1rem;
        width: 100%;

        a {
            position: relative;
            display: block;

            img {
                transition: all 0.4s ease;
                position: relative;
                height: 200px;
                width: 100%;
            }
        }
    }

    .details {
        padding-top: 0 !important;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;

        .tag {
            font-weight: 700;
            text-transform: uppercase;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-right: 0.25rem;
            display: inline-block;
            border-radius: 0.3rem;
            font-size: 10px;
            line-height: 32px;
            letter-spacing: 0.6px;
        }

        .course-tag {
            background-color: #A9D0F5;
            border-color: #ffe1c5;
            color: #0000FF;
        }

        .resource-tag {
            background-color: #ffead6;
            border-color: #ffe1c5;
            color: #FE9431;
        }

        .price {
            font-weight: 700;
            color: #10d876;
            padding-left: 1rem;
            padding-right: 1rem;
            float: right;
            font-size: 16px;
            line-height: 32px;
            letter-spacing: 0.6px;

            span {
                font-size: 10px;
            }
        }

        .content-title {
            font-weight: 700;
            margin-top: 1rem;
            font-size: 16px;
            line-height: 28px;

            a {
                color: #212529;
                text-decoration: none;
                outline: none;
            }
        }

        .content-description {
            font-weight: 600;
            color: #adb5bd;
            margin-top: 0.5rem;
            margin-left: 0;
            font-size: 12px;
        }

        .content-buttons {
            display: flex;
            width: 100%;
            justify-content: flex-end;

            .edit-button, .delete-button {
                font-family: "Open Sans", sans-serif;
                padding-top: 0.5rem;
                height: auto;
                text-decoration: none;
                outline: none;
                background-color: transparent;
                border: 0;

                i {
                    color: #2A2F7B;
                    margin-right: 1rem;
                    font-size: 20px;
                }
            }

            .delete-button {
                padding-bottom: 0.5rem;

                i {
                    color: red;
                }
            }

            .delete-discount-button {
                border: 0;
                background: transparent;
                color: red;
                margin-right: 1rem;
                font-size: 20px;
            }
        }
    }
}

.delete-content-discount-code-modal {
    width: 520px !important;
    margin-left: 35% !important;
    margin-top: 15% !important;
}
