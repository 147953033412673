a {
    text-decoration: none;
}

.dashboard-section_sidebar-right-main-section-item-card {
    color: black;
    
    .item-vard-name {
        font-size: small;
        font-weight: 400;
    }

    .item-vard-value {
        font-size: xx-large;
        font-weight: 700;
    }
}