.landing-page {

    .testimonial-section {
        padding-bottom: 1rem;
        padding-top: 1rem;
        background-color: #f7f7f7;

        .testimonial-section-container {
            margin-bottom: 3rem;
            margin-top: 3rem;
            margin-right: 13%;
            margin-left: 13%;

            .container-body {
                text-align: center;

                .title {
                    margin-bottom: 1.5rem;
                    font-size: 1.5rem;
                    font-style: italic;

                }
            }

            .ceo-edowie-section {
                align-items: center;
                justify-content: center;
                display: flex;

                .ceo-edowie-section-icon {
                    border-radius: 50%;
                    margin-right: 1rem;
                    vertical-align: middle;
                    border-style: none;
                    width: 84px;
                    height: 84px;
                }

                .ceo-edowie-section-user {
                    font-weight: 700;
                    font-size: 13.6px;
                    line-height: 23.8px;

                    .ceo-edowie-section-separator {
                        color: #1E74FD;
                        margin-left: 0.25rem;
                        margin-right: 0.25rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}