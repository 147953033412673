.my-profile {
    .balance {
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
        padding: 1.25rem;

        .balance-total-section {
            .balance-total-section-total {
                font-weight: 700;
                font-size: 60px;
                line-height: 1;
                text-align: center;
                //padding-right: 200px;
                //padding-top: 80px;
            }
        }

        .balance-retirement-section {
            padding-right: 35px;

            .balance-retirement-section-title {
                text-align: center;
                font-size: x-large;
            }

            .balance-retirement-section-buttons {

                .balance-retirement-section-buttons-button {
                    width: 100%;
                    font-weight: 700;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 0.5rem;
                    margin-top: 0.5rem;
                    margin-left: 0.25rem;
                    display: inline-block;
                    background-color: #2A2F7A;
                    text-align: center;
                    vertical-align: middle;
                    cursor: pointer;
                    user-select: none;
                    border: 1px solid transparent;
                    padding: 0.375rem 0.75rem;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    border-radius: 30px;
                    font-size: 10px;
                    line-height: 24px;
                    letter-spacing: 1px;
                }

                .balance-retirement-section-buttons-button:disabled {
                    opacity: 0.65;
                }
            }
        }

        .balance-commission-section {
            margin-top: 1.5rem;
            margin-bottom: 1rem;
            font-weight: 400;
            font-size: 1.125rem;
        }

        .balance-balance-history {
            margin-top: 1.5rem;
            font-weight: 700;
            margin-bottom: 1rem;
            font-size: 1.125rem;
        }

        .balance-transactions-details-section-date-section {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .accredited {
                color: lightskyblue;
            }

            .pending {
                color: lightgreen;
            }
            
            .denounced {
                color: red;
            }

            .cancelled {
                color: red;
            }

            .negative {
                color: red;
            }

            .balance-transactions-details-section-date-section-title {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-weight: 700;
                font-size: 14px;
            }
        }

        .balance-transactions-details-section {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            margin-top: 10px;
            position: relative;

            hr {
                margin-top: 1rem;
                margin-bottom: 1rem;
                border: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            .balance-transactions-details-section-container {
                margin-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                .balance-transactions-details-section-container-title {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 700;
                    font-size: 14px;
                }

                .balance-transactions-details-section-container-information-section {
                    display: flex;
                    font-size: 12px;
                    margin-top: 2px;
                    opacity: .7;
                    overflow: hidden;
                    white-space: pre;

                    .balance-transactions-details-section-container-information-section-item {
                        display: flex;
                        margin-right: 15px;

                        .balance-transactions-details-section-container-information-section-item-value {
                            font-weight: 600;
                            margin-left: 3px;
                        }

                        a {
                            color: black;
                            text-decoration: none;
                        }
                    }
                }
            }

            
        }
    }
}

.withdrawal-bank-transfer-modal {
    .modal-content {
        width: 650px;
    }
}

.withdrawal-bank-transfer-modal-legend {
    font-size: 12pt;
    font-weight: 400;
    margin-bottom: 20px;
}

@media screen and (max-width: 500px) {
    .withdrawal-bank-transfer-modal {
        .modal-content {
            width: 100%;
        }
    }
}