.landing-page {

    .edowie-section {
        padding-top: 3rem;
        display: block;

        .edowie-section-container{
            margin-bottom: 3rem;
            margin-top: 3rem;
            margin-right: 13%;
            margin-left: 13%;

            .edowie-section-container-body {
                --bs-gutter-x: 3rem;

                .section-left {
                    margin-bottom: 0;

                    .title {
                        margin-bottom: 0;
                        font-weight: bolder;
                        font-size: 1.125rem;
                    }

                    .description {
                        margin-top: 10px;
                        margin-bottom: 0;
                        font-size: 0.875rem;
                    }
                }

                .features {
                    --bs-gutter-x: 3rem;

                    .feature {
                        margin-bottom: 3rem ;
                        height: 100%;

                        .feature-icon {
                            color: #fff;
                            margin-bottom: 1rem;
                            background-color: #1E74FD;
                            display: inline-flex;
                            align-items: center;
                            justify-content: center;
                            height: 3rem;
                            width: 3rem;
                            font-size: 1.5rem;
                            border-radius: 0.5rem;
                            background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
                        }

                        .title {
                            font-size: 12pt;
                            font-weight: 700;
                        }

                        .description {
                            margin-bottom: 0;
                            font-size: 10pt;
                        }
                    }
                }
        
            }

            .edowie-section-container-buttons {
                padding-top: 35px;
                justify-content: center;
                display: flex;
                gap: 1rem;

                .button {
                    padding-left: 1.5rem;
                    padding-right: 1.5rem;
                    padding: 0.5rem 1rem;
                    font-size: 1.25rem;
                    line-height: 1.5;
                    border-radius: 0.3rem;
                    text-decoration: none;
                    display: inline-block;
                    font-weight: 400;
                    text-align: center;
                    vertical-align: middle;
                    cursor: pointer;
                    user-select: none;
                    border: 1px solid transparent;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    --bs-btn-padding-y: 0.5rem;
                    --bs-btn-padding-x: 1rem;
                    --bs-btn-font-size: 1.25rem;
                    --bs-btn-border-radius: 0.5rem;
                }
                .what-can-offer-button {
                    
                    color: #fff;
                    border-color: #1E74FD;
                    background-color: #e83088;
                    border: 1px solid white;
                }

                .see-eshop-example {
                    --bs-btn-bg: #1313cb;
                    --bs-btn-border-color: #1313cb;
                    background-color: #2A2F7A;
                    border: 1px solid white;
                    color: #fff;
                }
            }
        }
    }
}