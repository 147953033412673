.dashboard-section {
    background-color: #fbfcfe;
    padding-top: 70px;
    min-height: 450px;

    .dashboard-section-sidebar-left {
        display: flex;
        justify-content: center;
    }

    .dashboard-section-sidebar-right {
        display: flex;
        justify-content: center;

        .dashboard-section_sidebar-right-main-section {
            width: 100%;

            .dashboard-section_sidebar-right-main-section-title {
                font-size: 1.5rem;
                font-weight: 400;
                margin-bottom: 15px;
                margin-top: 15px;
            }

            .dashboard-section_sidebar-right-main-section-item-card {
                //position: fixed;
                display: flex;
                flex-direction: column;
                min-width: 0;
                word-wrap: break-word;
                background-color: #fff;
                background-clip: border-box;
                //border: 1px solid lightgray;
                //border-radius: 15px;
                border-radius: 0.5em;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;

                overflow: hidden;
                padding: 1rem;
                //margin: 1rem;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .dashboard-section  {
        padding-top: 0px;
        padding-bottom: 50px;
    }
}