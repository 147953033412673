.payment-method-card {
    background-color: #fff;
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 20px;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .payment-method-card-details {
        background-color: #fff;
    }
}