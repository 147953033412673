.admin-user-card {
    position: fixed;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px;
    border-radius: 15px;
    overflow: hidden;
    padding: 1rem;
    margin: 1rem;

    .admin-user-card-body {
        background-image: url(../../../../general/images/user-pattern.png);
        text-align: center;
        padding: 0.5rem;
        display: block;
        flex: 1 1 auto;
        min-height: 1px;
        background-position: top 30px center;
        background-repeat: no-repeat;
        background-color: transparent;

        h2 {
            font-weight: 700 !important;
            margin-top: 1rem !important;
            margin-bottom: 0.25rem !important;
            font-size: 16px !important;
            line-height: 1.4;
            color: #000 !important;
        }

        .admin-user-card-body-perfil {
            color: #adb5bd !important;
            margin-top: 0 !important;
            font-size: 12px !important;
        }

        .admin-user-card-body-buttons {
            padding: 0;
            margin-left: 0.25rem;
            display: inline-block;
            font-weight: 400;
            color: #515184;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            user-select: none;
            border: 1px solid transparent;
            //padding: 0.375rem 0.75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 0.25rem;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border-radius: 30px;
            width: 45px;
            height: 45px;
            border-radius: 45px;
            display: inline-block;
            line-height: 45px;
            text-align: center;
            background-color: #e8f1fa;
            text-decoration: none;
            outline: none;

            .bg-warning {
                background-color: #FE9431;
            }
        }

        .admin-user-card-body-messages {
             .dot-count {
                position: absolute;
                margin-top: 3px;
                margin-left: 18px;
                border-radius: 5px;
                width: 7px;
                height: 7px;
                line-height: 15px;
                text-align: center;
                display: inline-block;
                font-size: 10px;
            }
        }

        .agenda {
            .dot-count {
               position: absolute;
               margin-top: 3px;
               margin-left: 16px;
               border-radius: 5px;
               width: 7px;
               height: 7px;
               line-height: 15px;
               text-align: center;
               display: inline-block;
               font-size: 10px;
           }
       }

        .red {
            text-align: center;

            a {
                text-decoration: none;
            }

            h4 {
                line-height: 1.2;
                color: #111;
                margin-top: 0;
                font-size: 22px;

                span {
                    line-height: 1.2;
                    color: #111;
                    margin-top: 0;
                    font-weight: 500;
                    color: #adb5bd;
                    margin-top: 0.25rem;
                    display: block;
                    font-size: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .admin-user-card {
        position: relative;
    }
}
