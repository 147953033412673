.content {
    background-color: #fbfcfe;
    padding-top: 90px;
    padding-right: 20px;
    width: 100%;
    margin-bottom: 60px;
}

@media screen and (max-width: 768px) {
    .content {
        padding-top: 10px;
    }
}