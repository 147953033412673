.mont-font {
    font-family: "Montserrat", sans-serif;
}

.roboto-font {
    font-family: 'Roboto', sans-serif;
}

.open-font {
    font-family: 'Open Sans', sans-serif;
}

.fredoka-font {
    font-family: 'Fredoka One', cursive;
}

.fw-700 {
    font-weight: 700 !important;
}

.mb-1 {
    margin-bottom: 1rem !important;
}

.ml-1 {
    margin-left: 1rem !important;
}

.mb-2 {
    margin-bottom: 2rem !important;
}

.mt-4 {
    margin-top: 4rem !important;
}

.mr-1 {
    margin-right: 1rem !important;
}

.mr-4 {
    margin-right: 4rem !important;
}

.font-sm {
    font-size: 20px !important;
}

font-xss {
    font-size: 16px !important;
}

.ls-1 {
    letter-spacing: 1px;
}

.font-xss {
    font-size: 16px !important;
}

.font-xsssss {
    font-size: 10px !important;
}

.ls-1 {
    letter-spacing: 1px;
}

.text-grey-500 {
    color: #adb5bd !important;
}

.pl-3, .px-3 {
    padding-left: 3rem !important;
}

.form-group {

}

.form-group .icon-input {
    position: relative;
}

.form-group .icon-input i {
    position: absolute;
    left: 15px;
    top: 19px;
}

.form-group .input {
    height: 60px;
    line-height: 60px;
    display: block;
    border: 2px #eee solid;
    border-radius: 7px;
}

.error-message {
    color: red;
}

.error-message-input {
    border: 1px solid red !important; 
}

.edowie-wrap-cancel {
    background-color: #fcedec;
    border-top: 1px solid #f8b3b2;
    border-bottom: 1px solid #f8b3b2;
    border-right: 1px solid #f8b3b2;
    border-left: 4px solid #e34f45;
}

.edowie-wrap-message {
    display: flex;
    border-radius: 3px;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    position: relative;
    overflow: hidden;
}

.edowie-wrap-message .edowie-message-icon {
    width: 22px;
    height: 20px;
    position: relative;
    display: table;
    color: #e34f45;
}

.edowie-wrap-message .edowie-content-message {
    padding-left: 12px;
    width: 100%;
}

.edowie-wrap-message .edowie-content-message p {
    margin-top: 0px;
    margin-bottom: 0px;
}

.user-photo-big {
    width: 90px !important;
    height: 90px !important;
    border-radius: 50% !important;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.user-photo-small {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50% !important;
    margin-top: -0.4rem !important;
}

body {
    overflow-x: hidden;
}