
.favorite {

    .favorite-item {
        margin-top: 20px;
        
        .favorite-item-card {
            padding: 0;
            margin-bottom: 1.5rem;
            margin-right: 0.25rem;
            overflow: hidden;
            border-radius: 0.3rem;
            border: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
            width: 300px;

            .favorite-item-card-image {
                overflow: hidden;
                margin-bottom: 1rem;
                width: 100%;

                img {
                    transition: all 0.4s ease !important;
                    position: relative;
                    height: 200px;
                    width: 100%;
                }
            }

            .favorite-item-card-body {
                padding-top: 0;
                flex: 1 1 auto;
                min-height: 1px;
                padding: 1.25rem;

                .favorite-item-card-body-tag {
                    font-weight: 700;
                    text-transform: uppercase;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    margin-right: 0.25rem;
                    display: inline-block;
                    border-radius: 0.3rem;
                    font-size: 10px;
                    line-height: 32px;
                    letter-spacing: 0.6px;
                }

                .favorite-item-card-body-course-tag {
                    background-color: #A9D0F5;
                    border-color: #ffe1c5;
                    color: #0000FF;
                }

                .favorite-item-card-body-resource-tag {
                    background-color: #ffead6;
                    border-color: #ffe1c5;
                    color: #FE9431;
                }

                .favorite-item-card-body-group-tag {
                    background-color: #facccc;
                    border-color: #f8b8b8;
                    color: #E50202;
                }

                .favorite-item-card-body-price {
                    font-weight: 700;
                    color: #10d876;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    float: right;
                    font-size: 16px;
                    line-height: 32px;
                    letter-spacing: 0.6px;

                    .favorite-item-card-body-price-legend {
                        font-size: 10px;
                    }
                }

                .favorite-item-card-body-is-free {
                    font-weight: 700;
                    color: #10d876;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    float: right;
                    font-size: 16px;
                    line-height: 32px;
                    letter-spacing: 0.6px;

                    .favorite-item-card-body-is-free-legend {
                        font-size: 10px;
                    }
                }

                .favorite-item-card-body-title {
                    font-weight: 700;
                    margin-top: 1rem;
                    font-size: 16px;
                    line-height: 28px;

                    .favorite-item-card-body-title-anchor {
                        color: #212529;
                        text-decoration: none;
                    }
                }

                .favorite-item-card-body-description {
                    font-weight: 600;
                    color: #adb5bd;
                    margin-top: 0.5rem;
                    margin-left: 0;
                    font-size: 12px;
                }

                .favorite-item-card-body-button {
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;

                    .favorite-item-card-body-button-remove { 
                        background-color: #009378;
                        margin-left: 0.5rem;
                        margin-bottom: 0.5rem;
                        display: inline-block;
                        border-radius: 0.3rem;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        border: 0;

                        .favorite-item-card-body-button-remove-icon {
                            line-height: 50px;
                            color: #fff;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}