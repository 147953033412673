.diary {
    background-color: #fbfcfe;
    padding-top: 70px;
    display: block;
    margin: 0px 20px 20px;
    margin-bottom: 60px;

    .diary-title {
        margin-top: 15px;
        padding-bottom: 15px;
        font-weight: 400;
        font-size: 1.5rem;
    }


    .diary-container {
        margin-bottom: 1.5rem;
        width: 100%;
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

        .diary-container-past-events {
            color: lightgray;

            a {
                color: lightgray !important;
                text-decoration: none !important;
            }
        }

        .profile-country-container {
            display: flex;
            justify-content: center;

            .profile-country-container-flag {
                border-radius: 50%;
                width: 35px;
                height: 35px;
            }

            .profile-country-container-country {
                font-weight: 600;
                padding-left: 0.5rem;
                margin-top: 0.25rem;
                line-height: 28px;
            }

            .profile-country-container-time-use {
                font-weight: 600;
                padding-left: 0.5rem;
                margin-top: 0.5rem;
            }
        }

        .current-date-section {
            display: flex;
            justify-content: space-around;
            .diary-container-current-date {
                font-size: 24px;
                padding-top: 0.5rem ;
                font-weight: 400;
                white-space: nowrap;
            }
        }

        .diary-container-event-section-date-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;

            .diary-container-event-section-date-container-date {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                font-weight: 700;
                font-size: 14px;
            }
        }

        .diary-container-event-section {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            margin-bottom: 10px;
            position: relative;

            hr {
                margin-top: 1rem;
                margin-bottom: 1rem;
                border: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            .diary-container-event-section-container {
                display: flex;
                justify-content: flex-start;


                .diary-container-event-section-reference-container {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    margin-top: 5px;
                    cursor: pointer;

                    .diary-container-event-section-reference-container-reference {
                        width: 32px;
                        height: 32px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                    }

                    .diary-container-event-section-reference-container-group-reference {
                        background-color: #facccc;
                    }

                    .diary-container-event-section-reference-container-particular-reference {
                        background-color: #1e74fd;
                    }
                }

                .diary-container-event-section-item-container {
                    margin-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .diary-container-event-section-item-container-item-title {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-weight: 700;
                        font-size: 14px;

                        a {
                            color: black;
                            text-decoration: none;
                        }
                    }

                    .diary-container-event-section-item-container-details {
                        display: flex;
                        font-size: 12px;
                        opacity: .7;
                        white-space: pre;
                        overflow: hidden;
                        margin-top: 2px;

                        .diary-container-event-section-item-container-details-date {
                            opacity: 1;
                            white-space: nowrap;
                            display: inline-block;
                        }

                        .diary-container-event-section-item-container-details-event {
                            display: flex;


                            .diary-container-event-section-item-container-details-event-detail {
                                margin-left: 15px;
                                display: flex;

                                a {
                                    color: black;
                                    text-decoration: none;
                                }

                                .diary-container-event-section-item-container-details-event-detail-value {
                                    margin-left: 3px;
                                    font-weight: 600;

                                    button {
                                        background-color: transparent;
                                        border: 0;
                                        color: inherit;
                                        font-weight: 600;
                                    }
                                }
                            }
                        }

                        @media screen and (max-width: 768px) {
                            .diary-container-event-section-item-container-details-event {
                                display: block;
                            }
                        }
                    }
                }
            }

            .diary-container-event-section-buttons-container {
                display: flex;
                justify-content: center;
                margin-top: 5px;

                .diary-container-event-section-buttons-container-cancel {
                    color: red;
                    cursor: pointer;
                    border: 0;
                    background-color: transparent;
                    font-size: 15pt;
                }

                .diary-container-event-section-buttons-container-conference {
                    color: #2A2F7A;
                    cursor: pointer;
                    border: 0;
                    background-color: transparent;
                    font-size: 15pt;
                }

                .diary-container-event-section-buttons-container-qualify {
                    color: orange;
                    cursor: pointer;
                    border: 0;
                    background-color: transparent;
                    font-size: 15pt;
                }

                .diary-container-event-section-buttons-container-denunciate {
                    color: red;
                    cursor: pointer;
                    border: 0;
                    background-color: transparent;
                    font-size: 15pt;
                }
            }

            @media screen and (max-width: 768px) {
                .diary-container-event-section-buttons-container {
                    margin-top: 20px;
                }

                .diary-container-event-section-container {
                    margin-left: 10px;
                }
            }
        }
    }
}

.diary-qualify-modal {
    margin-left: 40% !important;
    width: 500px !important;
    margin-top: 5% !important;
}

@media screen and (max-width: 500px) {
    .diary {
        padding-top: 0px;
    }
}