.donations {
    background-color: #fbfcfe;
    padding-top: 70px;
    display: block;
    margin: 0px 20px 20px;
    margin-bottom: 60px;

    .donations-title {
        margin-top: 15px;
        padding-bottom: 15px;
        font-weight: 400;
        font-size: 1.5rem;
    }

    .donations-content {
        width: 100%;
        padding-right: 0;
        margin-right: 1rem;
        background-color: #fff;

        .donations-choose-amount-header {

            .donations-choose-amount-title {
                background-color: #2A2F7A;
                width: 100%;
                border-radius: 0.3rem;
                border: 0;
                padding-top: 15px;
                padding-bottom: 15px;
                padding-left: 20px;
                color: white;
            }
        }

        .donations-choose-amount-details {
            border-bottom: 1px solid #d1d7dc;
            border-left: 1px solid #d1d7dc;
            border-radius: 5px;
            border-right: 1px solid #d1d7dc;
            box-shadow: 0 1px 5px rgba(0, 0, 0, .08);
            padding: 1.1rem;

            .default-amount {
                width: 100%;
                border: 0;
                background: transparent;

                .default-amount-button {
                    width: 100%;
                    height: 40px;
                    background-color: #2a2f7a;
                    border-radius: 10px;
                    color: #fff;
                    cursor: pointer;
                    display: inline-block;
                    font-size: 10pt;
                    font-weight: 700;
                    letter-spacing: 1px;
                    line-height: 25px;
                    margin: .5rem;
                    padding: .5rem;
                    text-align: center;
                    text-transform: uppercase;
                    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
                    user-select: none;
                    vertical-align: middle;
                }

                .selected {
                    background-color: blue;
                }
            }

            .p-r-20 {
                padding-right: 20px;
            }

            .error-form p {
                color: #e34f45;
                font-size: 13px;
                margin: 0;
                text-align: left;
            }

            .donations-save-button {
                justify-content: flex-end;
                display: flex;
            }
        }
    }
}