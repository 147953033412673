.view-content-tabs {
    .qualification-section {
        width: 100%;
        padding: 1.5rem;
        margin-top: 0.5rem;
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
        min-height: 290px;
        text-align: center;

        .qualification-value {
            padding-top: 50px;

            h2 {
                font-weight: 700;
                color: #212529;
                margin: 0;
                font-size: 50px;
                line-height: 1;
            }

            .qualification-star {
                text-align: center;
                width: 100%;
                display: block ;
                
                .reviews {
                    font-weight: 600;
                    color: #6c757d;
                    margin-top: 0.25rem;
                    font-size: 10px;
                }
            }
        }

        .qualification-for-star {
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            margin-right: 35px;
            margin-left: 0;
            margin-top: 20px;
            border-radius: 0.2rem;
            background-color: #f5f5f5;

            .star-item {
                margin-top: 5px;

                .star {
                    width: 12px;
                }

                .star-number {
                    font-weight: 600;
                    color: #6c757d;
                    margin-left: 0.25rem;
                    display: inline;
                    font-size: 14px;
                }

                .progress-bar {
                    position: relative;
                    background-color: #eee;
                    border-radius: 10px;
                    box-sizing: border-box;
                    height: 5px;
                    margin-top: 7px;
                    width: 100%;

                    .bar-percentage {
                        background-color: #aaa;
                        border-radius: 10px;
                        height: 5px;
                        padding: 0;
                        width: 0;
                        transition: all 0.4s ease;
                    }
                }

                .star-percentage {
                    font-weight: 600;
                    color: #343a40;
                    font-size: 12px;
                }
            }
        }
    }
}