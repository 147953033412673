.buy-section-promocode {
    margin-bottom: 20px;
    
    .buy-section-promocode-code {
        .buy-section-promocode-code-label {
            color: white;
        }
    }

    .buy-section-promocode-apply {
        margin-top: 27px;
        
        .buy-section-promocode-apply-button {
            margin-left: 10px;
            border: 0;
            font-weight: 600;
            color: #1E74FD;
            width: 100%;
            display: inline-block;
            border-radius: 0.3rem;
            border: 0;
            background-color: #fff;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            user-select: none;
            line-height: 1.5;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            font-size: 12px;
            height: 40px
        }
    }
}