.add-publication {
    margin-top: 10px;

    .container {
        justify-content: center !important;
        display: flex;
        width: 100%
    }

    .form {
        height: auto;
        border-radius: 5px;
        box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
        padding-top: 15px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: white;
        margin-top: 5px;
        width: 100%;
        margin-bottom: 5px;

        .form-group {
            display: flex;

            .message {
                width: calc(100% - 20px);
                margin-left: 10px;
                border-radius: 10px;
                font-weight: 500;
                border: 0;
                background-color: transparent;
                border: 1px solid lightgrey;
                padding-left: 10px;
                font-size: 14px;
                height: 35px;
            }

            .w-100 {
                width: 100%;
            }

            .error-message {
                color: red;
                padding-left: 10px;
            }

            .post-send {
                margin-top: 10px;
                display: flex;
                justify-content: flex-end;
                margin-right: 10px;

                button {
                    font-weight: 700;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 0.5rem;
                    border: 1px solid transparent;
                    background-color: #2A2F7A;
                    font-size: 10px;
                    border-radius: 10px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    width: 100px;
                    height: 45px;
                    margin-top: 10px;
                }

                &:hover {
                    button {
                        background-color: #2A2F7A;
                        color: #fff;
                    }
                }
            }

            @media screen and (max-width: 500px) {
                .post-send {
                    margin-top: 0px;
                    display: flex;
                    justify-content: center;
                }
            }


            .file-upload-container {
                display: flex;
                width: 100%;
                margin-top: 10px;

                button:disabled {
                    background-color: #eaeaea;
                    border: 1px solid #ccc;
                    color: #666;
                    cursor: default;
                }
                
                .video-file-upload {
                    width: 50%;
                }

                .image-file-upload {
                    width: 50%;
                }
            }
        }
    }
}
