.notifications {
    .notifications-section {

        .notification-card {
            cursor: default;
            padding-left: 3rem;
            margin-bottom: 1rem;
            width: 100%;
            border: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border-radius: 0.25rem;

            a {
                height: auto;
            }

            .notification-show-more {
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                padding: 0.5rem;
                margin-top: 1.5rem;
                margin-left: 1rem;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                border-radius: 30px;
                font-size: 10px;
                line-height: 24px;
                letter-spacing: 1px;
                width: 100px;
                background-color: #2A2F7A;
                border: 0;
                height: 42px;
            }
        }
    }
}