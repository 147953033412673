.registration-list {
    background-color: #fff !important;

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .registration-list-section {
        width: 100%;

        .registration-list-section-item {

            .registration-list-section-item-user-image {
                text-align: left;

                .registration-list-section-item-user-image-user-avatar {
                    float: left;
                    margin-top: 10px;
                    margin-left: 10px;
                    
                    .registration-list-section-item-user-image-user-avatar-user-profile {
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        margin-right: 0.5rem;
                        box-shadow: none;
                        float: right ;
                    }
                }
            }

            .registration-list-section-item-details {
                text-align: left;

                .registration-list-section-item-details-username {
                    font-weight: 600;
                    color: #343a40;
                    margin-bottom: 0;
                    font-size: 12px;
                }

                .registration-list-section-item-details-timezone {
                    justify-content: flex-start;
                    display: flex;
                    text-align: center;
                    margin-top: 0.5rem;

                    .registration-list-section-item-details-timezone-flag {
                        border-radius: 50%;
                        width: 35px;
                        height: 35px;
                    }

                    .registration-list-section-item-details-timezone-country {
                        margin-left: 0.5rem;

                        .registration-list-section-item-details-timezone-country-name {
                            color: #adb5bd;
                            margin-bottom: 0.25rem;
                            margin-top: 0;
                            font-size: 12px;
                            display: flex;
                            justify-content: flex-start;
                        }

                        .registration-list-section-item-details-timezone-country-timezone {
                            font-weight: 700;
                            margin-top: 0;
                            font-size: 12px;
                            color: #111;
                            display: flex;
                            justify-content: flex-start;
                        }
                    }
                }
            }
        }
    }
}