.user-profile {
    width: 100%;
    background-color: #fbfcfe;
    padding-top: 70px;
    font-family: "Montserrat", sans-serif;
    display: block;

    .user-profile-container {
        margin-bottom: 1rem;
        max-width: 100%;
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;

        .gradiant-bottom {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) -11%, rgba(0, 0, 0, 0.9) 100%);
            width: 100%;
            height: 40px
        }

        .cover-image-container {
            display: flex;
            justify-content: center;
            height: 200px;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.01) -11%, rgba(0, 0, 0, 0.9) 100%);
            border-radius: 0.3rem;
            
            .cover-image {
                //width: 100%;
                //height: 200px;
                padding-bottom: 10px;
                padding-top: 10px;
            }
        }

        .user-information {
            padding-left: 3rem;
            padding-right: 1.5rem;
            width: 100%;
            display: block;
            min-height: 1px;
            padding: 1.25rem;

            .card-user-photo {
                background-position: top 30px left;
                width: 200px;

                figure {
                    margin-left: 10px;
                    width: 155px;
                    overflow: hidden;
                }

                .user-photo {
                    width: 150px;
                    height: 150px;
                    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
                    float: right;
                    border-radius: 50%;
                }
            }

            .relations-user-profile {
                display: flex;
                margin-top: 50px;

                .ml-2, .mx-2 {
                    margin-left: 2rem;
                }

                .mr-3 {
                    margin-right: 3rem;
                }

                .details {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    color: #222;
                    text-decoration: none;
                    
                    .relation-quantity {
                        font-weight: 600;
                        font-size: 18px;
                        line-height: 28px;
                    }

                    .relation-title {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 28px;
                    }
                }
            }

            .username {
                font-weight: 700;
                margin-top: 1rem;
                margin-bottom: 1rem;
                font-size: 18px;
                margin-left: 1rem;
            }

            .user-first-last-name {
                font-weight: 700;
                color: #343a40;
                margin-top: 1rem;
                margin-bottom: 1rem;
                font-size: 14px;
                margin-left: 1rem;
            }

            .user-country {
                margin-left: 1rem;
                margin-top: 0.5rem;
                display: flex;

                .flag {
                    border-radius: 50%;
                    width: 35px;
                    height: 35px;
                }

                .country-name {
                    font-weight: 600;
                    color: #adb5bd;
                    padding-left: 0.5rem;
                    margin-top: 0.25rem;
                    font-size: 12px;
                    line-height: 28px;
                }
            }

            .user-about-me {
                margin-left: 1rem;

                .user-about-me-title {
                    font-weight: 700;
                    color: #343a40;
                    margin-top: 1.5rem;
                    font-size: 14px;
                } 

                .user-about-me-description {
                    font-weight: 600;
                    color: #adb5bd;
                    padding-left: 0;
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 28px;
                } 
            }

            .user-profile-buttons {
                justify-content: center;
                display: flex;
  
                .user-profile-buttons-relation {
                    padding-top: 0.25rem;

                    .relation-button {
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 0.5rem;
                        margin-top: 0.5rem;
                        margin-left: 0.25rem;
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                        cursor: pointer;
                        user-select: none;
                        border:0;
                        border-radius: 30px;
                        font-size: 10px;
                        line-height: 24px;
                        letter-spacing: 1px;
                        width: 150px;
                        background-color: #2A2F7A;
                    }
                }
                
            }
        }
    }
}
