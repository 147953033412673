.filters {
    .container {
        margin-top: 20px;

        .item {
            justify-content: space-between;
            display: flex;
            width: 100%;
            border-radius: 5px;
            box-shadow: 0 1px 5px rgba(0, 0, 0, 0.25);
            padding: 20px;
            margin-bottom: 10px;
            background-color: white;
            list-style: none;
            margin-top: 5px;

            .applied-filters {
                display: flex;
                justify-content: space-between;
                width: 100%;

                .legend {
                    margin-top: 10px;

                    .selected-filter {
                        margin-left: 10px;
                    }
                }

                .filter button {
                    font-weight: 700;
                    color: #fff;
                    text-transform: uppercase;
                    padding: 0.5rem;
                    border: 1px solid transparent;
                    background-color: #2A2F7A;
                    font-size: 10px;
                    border-radius: 10px;
                    line-height: 24px;
                    letter-spacing: 1px;
                    width: 100px;
                    height: 45px;
                }
            }

            .apply-filters {
                width: 100%;

                .options-and-button {
                    display: flex;
                    justify-content: space-between;

                    .form-check-input:checked {
                        background-color: #2A2F7A;
                        border-color: #2A2F7A;
                    }

                    .apply {
                        padding-top: 25px;

                        button {
                            font-weight: 700;
                            color: #fff;
                            text-transform: uppercase;
                            padding: 0.5rem;
                            border: 1px solid transparent;
                            background-color: #2A2F7A;
                            font-size: 10px;
                            border-radius: 10px;
                            line-height: 24px;
                            letter-spacing: 1px;
                            width: 100px;
                            height: 45px;
                        }
                    }
                }
            }
        }
    }
}
