.review-list {
    padding-top: 20px;
    background-color: #fff !important;

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .review-list-section {
        width: 100%;

        .review-list-section-item {

            .user {
                text-align: left;

                .user-avatar {
                    float: left;

                    .review-user-profile {
                        border-radius: 50%;
                        width: 50px;
                        height: 50px;
                        margin-right: 0.5rem;
                        box-shadow: none;
                        float: right ;
                    }
                }
            }

            .review {
                text-align: left;

                .username {
                    font-weight: 600;
                    color: #343a40;
                    margin-bottom: 0;
                    font-size: 12px;
                }

                .date {
                    font-weight: 500;
                    color: #adb5bd;
                    margin-top: 0.5rem;
                    margin-bottom: 0;
                    display: block;
                    font-size: 10px;
                }

                .description {
                    font-weight: 500;
                    color: #adb5bd;
                    margin-top: 0.5rem;
                    font-size: 12px;
                    line-height: 24px;
                }
            }
        }
    }
}