@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner-container {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 50px;
    margin-top: 300px;

    .loading-spinner {
        width: 80px;
        height: 80px;
        border: 5px solid #f3f3f3;
        border-top: 5px solid #515184;
        border-radius: 50%;
        animation: spinner 1.5s linear infinite;
    }
}