.chat {
    border-radius: 5px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    padding: 10px;
    //max-height: 500px;
    overflow: visible;
    //margin-bottom: 30px;
}

.chat-without-recipient {
    font-weight: 600;
    color: #fff;
    margin-left: 1.5rem;
    margin-top: 18px;
    font-size: 18px;
}