.information-section {
    width: 100%;
    margin-left: 20px;
    margin-top: 1.5rem;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;

    .card-details {
        background: #fff;
        border: 0;
        overflow: hidden;
        margin-bottom: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        border-radius: 0.25rem;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

        .card-header {
            background: #fff;
            padding: 20px 30px;
            border-bottom: 0 solid #000;
            border-radius: 0;
            margin-bottom: -1px;
            background-color: #f5f5f5;

            h5 {
                margin-bottom: 0;

                .collapse-expand-button {
                    cursor: pointer;
                    background-color: transparent;
                    font-weight: 600;
                    text-align: center;
                    vertical-align: middle;
                    user-select: none;
                    border: 1px solid transparent;
                    padding: 0.375rem 0.75rem;
                    line-height: 1.5;
                    border-radius: 0.25rem;
                    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    font-size: 14px;
                }
            }
        }

        p {
            padding: 1rem;
            font-size: 15px;
            line-height: 36px;
            color: #666;
        }

        .card-body {
            background: #fff;
            border-bottom: 0 solid #000;
            padding: 0.5rem;
            display: flex;
            min-height: 1px;

            .item-label {
                font-weight: 500;
                color: #adb5bd;
                margin-left: 0.5rem;
                font-size: 12px;
            }

            .item-content {
                font-weight: 700;
                color: #212529;
                margin-left: auto;
                font-size: 12px;
                padding-right: 10px
            }

            .item-footer-label {
                font-weight: 700;
                color: #212529;
            }
        }
    }
}

.view-content-tabs {
    .information-section {
        margin-left: 0px;
    }
}
