.service-promocodes-service-item {
    padding: 0;
    margin-bottom: 1.5rem;
    margin-right: 0.25rem;
    overflow: hidden;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    width: 300px;

    .service-promocodes-service-item-cover-image {
        overflow: hidden;
        margin-bottom: 1rem;
        width: 100%;

        img {
            transition: all 0.4s ease;
            position: relative;
            height: 200px;
            width: 100%;
        }
    }

    .service-promocodes-service-item-details {
        padding-top: 0 !important;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;

        .service-promocodes-service-item-details-tag {
            font-weight: 700;
            text-transform: uppercase;
            padding-left: 1rem;
            padding-right: 1rem;
            margin-right: 0.25rem;
            display: inline-block;
            border-radius: 0.3rem;
            font-size: 10px;
            line-height: 32px;
            letter-spacing: 0.6px;
        }

        .service-promocodes-service-item-details-course-tag {
            background-color: #A9D0F5;
            border-color: #ffe1c5;
            color: #0000FF;
        }

        .service-promocodes-service-item-details-resource-tag {
            background-color: #ffead6;
            border-color: #ffe1c5;
            color: #FE9431;
        }

        .service-promocodes-service-item-details-group-tag {
            background-color: #facccc;
                border-color: #f8b8b8;
            color: #E50202;
        }

        .service-promocodes-service-item-details-title {
            font-weight: 700;
            margin-top: 1rem;
            font-size: 16px;
            line-height: 28px;
        }

        .service-promocodes-service-item-details-promocodes-tags {
            margin-top: 20px;
            margin-bottom: 20px;

            .service-promocodes-service-item-details-promocodes-tags-tag {
                border-radius: .3rem;
                display: inline-block;
                font-size: 10px;
                font-weight: 700;
                letter-spacing: .6px;
                line-height: 32px;
                margin-right: 10px;
                padding-left: 10px;
                padding-right: 10px;
                margin-top: 10px;
                text-transform: uppercase;
                background-color: #FF7F50;
                border-color: #ffe1c5;
                color: white;
                cursor: pointer;
            }

            .service-promocodes-service-item-details-promocodes-tags-tag-used {
                text-decoration: line-through;
                cursor: default;
            }
        }

        .service-promocodes-service-item-details-promocodes-discount-delete-section {
            display: flex;;
            justify-content: space-between;

            .show-discount {
                font-size: 15px;
                line-height: 30px;
                margin-bottom: 0;
                text-transform: none;
                font-weight: 700;
                display: inherit;
                height: auto;
                color: #212121;
            }

            .delete-button-section {
                display: flex;
                justify-content: flex-end;
    
                .delete-button-section-delete-button {
                    padding-bottom: 0.5rem;
                    padding-top: 0.5rem;
                    height: auto;
                    text-decoration: none;
                    outline: none;
                    background-color: transparent;
                    border: 0;

                    i {
                        color: red;
                    }
                }
            }
        }
    }
}

.delete-promotions-code-modal {
    width: 520px !important;
    margin-left: 35% !important;
    margin-top: 15% !important;
}