.availability-classes {
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    padding-bottom: 60px;
    
    .availability-classes-title {
        font-weight: 400;
        display: block;
        font-size: 25px;
    }

    .date-picker-container {
        display: flex;
        justify-content: space-around;
        margin-bottom: 20px;
        margin-top: 20px;

        .date-button {
            background-color: #2A2F7A;
            border-color: #2A2F7A;
            color: white;
            border: 1px solid transparent;
            border-radius: 0.25em;
            display: inline-block;
            font-size: 1em;
            font-weight: 400;
            line-height: 1.5;
            padding: 0.4em 0.65em;
            text-align: center;
            user-select: none;
            vertical-align: middle;
        }

        .change-week-legend {
            font-size: small;
            margin-left: 10px;
        }

        .user-information {
            display: flex;
            margin-right: 20px;

            .country-logged-user {

                .container {
                    display: flex;

                    .flag {
                        border-radius: 50% !important;
                        width: 35px;
                        height: 35px;
                    }

                    .country {
                        font-weight: 600;
                        padding-left: 0.5rem;
                        margin-top: 0.25rem;
                        line-height: 28px;
                    }
                }
            }

            .time-use {
                font-weight: 600;
                padding-left: 0.5rem;
                margin-top: 0.4rem;
            }
        }

        .availability-classes-duration {
            margin-top: 10px;

            .label {
                font-weight: 600;
            }

            .duration {
                margin-left: 10px;
                width: auto;
                text-align: center;
                color: #333;
                padding-left: 5px;
                padding-right: 5px;
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                background-color: #f5f5f5;
                border-color: rgba(0, 0, 0, .1) rgba(0, 0, 0, .1) rgba(0, 0, 0, .25);
                text-shadow: 0 1px 1px rgba(255,255,255,.75);
                box-shadow: inset 0 1px 0 rgba(255,255,255,.2), 0 1px 2px rgba(0,0,0,.05);
                border: 1px solid;
            }
        }
    }

    .details {
        display: grid;
        gap: 1rem;
        grid-auto-flow: dense;
        //grid-auto-rows: 22rem;
        grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));

        .references {
            grid-column: span 2;

            .references-container {
                margin-left: 10px;

                .title {
                    background-color: #2A2F7A;
                    padding: 1rem;
                    width: 100%;
                    border-radius: 0.3rem;
                    border: 0;

                    h4 {
                        margin-top: 0.5rem;
                        margin-bottom: 0;
                        font-size: 18px;
                        color: #fff;
                        font-weight: 600;
                        text-align: center;
                    }
                }

                .reference {
                    margin-top: 10px;
                    display: flex;

                    .past {
                        border: 1px solid lightgray !important;
                        background-color: lightgray !important;
                        width: 50px;
                        margin-right: 10px;
                    }

                    .available {
                        border: 1px solid mediumseagreen !important;
                        background-color: mediumseagreen !important;
                        width: 50px;
                        margin-right: 10px;
                    }

                    .reserved {
                        border: 1px solid red !important;
                        background-color: red !important;
                        width: 50px;
                        margin-right: 10px;
                    }

                    .group-experience {
                        border: 1px solid orange;
                        background-color: orange;
                        width: 50px;
                        margin-right: 10px;
                    }

                    .legend {
                        font-weight: 700;
                        font-size: 14px;
                    }
                }
            }
        }

        .calendar {
            grid-column: span 7;
        }

        .fc {
            .fc-button-primary {
                background-color: #2A2F7A;
                border-color: #2A2F7A;
                color: white;
            }

            .fc-prev-button {
                margin-left: 10px;
            }
        }
    }

    .availability-classes-buttons {
        display: flex;
        justify-content: space-between;

        .button {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            border: 1px solid transparent;
            font-size: 10px;
            border-radius: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 150px;
            height: 45px;
            margin-top: 10px;
        }

        .back-button {
            background-color: gray;
        }

        .save-button {
            background-color: #2A2F7A;
        }

        .deactivate-button {
            background-color: red;
        }
    }

    .fc-past-event {
        border: 2px solid lightgray;
        background-color: lightgray;
        color: black !important;
        width: 100%;
        height: auto;
        padding-left: 5px;
    }
    
    .fc-reserved-event {
        border: 1px solid red;
        background-color: red;
        color: #fff;
        width: 100%;
        height: 100%;
        padding-left: 5px;
    }

    .fc-event-group-experience {
        border: 1px solid orange !important;
        background-color: orange !important;
        color: white !important;

        .remove-event {
            margin-right: 5px;
            cursor: pointer;
            color: red;
            font-weight: 700;
        }
    }
    
    .fc-available-event {
        border: 1px solid mediumseagreen;
        background-color: mediumseagreen;
        color: #fff;
        width: 100%;
        height: 100%;
        padding-left: 5px;

        .available-event {
            display: flex;

            .remove-event {
                margin-right: 5px;
                cursor: pointer;
                color: red;
                font-weight: 700;
            }
        }

    }
    
    .fc-v-event {
        background-color: transparent;
        border: 0;
        display: block;
    }
    
    .fc-col-header-cell {
        background-color: #2A2F7A;
    
        a {
            text-decoration: none;
            color: #fff;
        }
    }
}

.availability-classes-modal {
    width: 520px !important;
    margin-left: 35% !important;
    margin-top: 15% !important;
}