.publications {
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
    border-radius: 0.3rem;
    border: 0;
    margin-bottom: 20px;

    .no-data {
        text-align: center;
        font-weight: 400;
        display: block;
        font-size: 25px;
    }

    .publications-title {
        padding-bottom: 0;
        margin-bottom: 1rem;
        min-height: 1px;
        padding-top: 1.25rem;
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        h2 {
            font-weight: 700;
            font-size: 25px;
        }
    }

    .publications-container {
        margin-top: 20px;

        .publications-list {
            width: 100%;
        }
    }
}
