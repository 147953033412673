.my-profile {
    .edit-profile {
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

        .title {
            padding-bottom: 0;
            min-height: 1px;
            padding: 1.25rem;

            .about-me {
                font-weight: 400;
                display: block;
                font-size: 25px;
            }

            .subtitle {
                white-space: pre-line;
                font-size: 14px;
            }
        }

        .recommendations {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            width: 100%;
            font-size: small;

            li {
                list-style: none;
            }

            .size {
                margin-left: 0.5rem;
                margin-right: 0.5rem;
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 8px;
                line-height: 25px;
                text-align: center;
                background-color: #000;
            }
        }

        .form {
            padding: 1.5rem;
            margin-bottom: 0;
            width: 100%;
            border: 0;
            flex: 1 1 auto;
            min-height: 1px;

            .file-card {
                padding-top: 10px;
                padding-bottom: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                //align-items: f;
            }

            .save-button {
                display: flex;
                justify-content: flex-end;
                margin-top: 25px;
            }

            .video-required {
                margin-left: 10px;
            }

            textarea {
                height: 150px;
            }
        }

        .social-neteworks-title {
            min-height: 1px;
            padding-top: 10px;
            padding-bottom: 10px;

            .social-neteworks {
                font-weight: 400;
                display: block;
                font-size: 25px;
            }
        }
    }
}
