.users-section {
    background-color: #fbfcfe;
    padding-top: 70px;
    display: block;
    margin: 0px 20px 20px;
    margin-bottom: 60px;
    
    .users-section-title {
        margin-top: 1.5rem;
        font-weight: 700;
        margin-bottom: 1rem;
        font-size: 1.125rem;
    }

    .users-section-grid {
        margin-top: 40px;

        .users-section-grid-image-column {
            .users-section-grid-image-column-header {
                display: flex;
                justify-content: center;
                padding-top: 5px;
                margin-bottom: 10px;

                .users-section-grid-image-column-header-user-photo {
                    width: 40px !important;
                    height: 40px !important;
                    border-radius: 50% !important;
                    margin-top: -0.4rem !important;
                }
            }
        }

        .users-section-grid-user-details-column {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            position: relative;

            hr {
                margin-top: 1rem;
                margin-bottom: 1rem;
                border: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            .users-section-grid-user-details-column-container {
                margin-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                .users-section-grid-user-details-column-container-username {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 700;
                    font-size: 14px;
                }

                .users-section-grid-user-details-column-container-person {
                    display: flex;
                    font-size: 12px;
                    margin-top: 2px;
                    opacity: .7;
                    overflow: hidden;
                    white-space: pre;

                    .users-section-grid-user-details-column-container-person-item {
                        display: flex;
                        margin-right: 15px;
    
                        .users-section-grid-user-details-column-container-person-item-value {
                            font-weight: 600;
                            margin-left: 3px;
                        }
                    }
                }
            }

            
        }

        .users-section-grid-user-balance-column {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            position: relative;

            hr {
                margin-top: 1rem;
                margin-bottom: 1rem;
                border: 0;
                border-top: 1px solid rgba(0, 0, 0, 0.1);
            }

            .users-section-grid-user-balance-column-container {
                margin-left: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                .users-section-grid-user-balance-column-container-balance {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 700;
                    font-size: 14px;
                }

                .users-section-grid-user-balance-column-container-commission {
                    display: flex;
                    font-size: 12px;
                    margin-top: 2px;
                    opacity: .7;
                    overflow: hidden;
                    white-space: pre;

                    .users-section-grid-user-balance-column-container-commission-item {
                        display: flex;
                        margin-right: 15px;
    
                        .users-section-grid-user-balance-column-container-commission-item-value {
                            font-weight: 600;
                            margin-left: 3px;
                        }
                    }
                }
            }
        }

        .users-section-grid-user-buttons-column {
            display: flex;
            justify-content: center;
            margin-top: 5px;

            .users-section-grid-user-buttons-column-change-profile {
                color: #2A2F7A;
                cursor: pointer;
                border: 0;
                background-color: transparent;
                font-size: 15pt;
                margin-left: 30px;
            }

            .users-section-grid-user-buttons-column-change-profile-disabled {
                color: lightgray;
            }
        }

        @media screen and (max-width: 768px) {
            .users-section-grid-user-buttons-column {
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
    }
}

.change-profile-modal {
    .modal-content {
        width: 550px;
    }

    .change-profile-modal-content {
        margin-top: 5px;
        margin-bottom: 20px;
    }
}

.ambassador-modal {
    .modal-content {
        width: 550px;
    }

    .ambassador-modal-content {
        margin-top: 5px;
        margin-bottom: 20px;
    }
}

.update-balance-information-modal {
    .modal-content {
        width: 550px;
    }

    .update-balance-information-modal-content {
        margin-top: 5px;
        margin-bottom: 20px;
    }
}