.particular-experience-pay-section {
    padding-top: 75px;

    .header {
        width: 100%;
        margin-bottom: 10px;
        margin-left: 20px;
        margin-top: 10px;
    }

    .particular-experience-pay {
        display: grid;
        gap: 1rem;
        grid-auto-flow: dense;
        grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));

        .particular-experience-pay-payment {
            grid-column: span 8;
        }

        .particular-experience-pay-payment-details {
            grid-column: span 4;
        }
    }

    .particular-experience-pay-buttons {
        display: flex;
        justify-content: space-between;
        margin-bottom: 75px;
        margin-left: 20px;

        .button {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            border: 1px solid transparent;
            font-size: 10px;
            border-radius: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 150px;
            height: 45px;
            margin-top: 10px;
        }

        .back-button {
            background-color: gray;
            text-decoration: none;
            text-align: center;
        }
    }
}