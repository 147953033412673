.footer {
    height: 60px;
    text-align: right;
    padding-top: 15px;
    padding-right: 10px;
    font-weight: 700;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
    border: 0;
    line-height: 1.75;
    font-size: 14px;
    background-color: #fff;
    //position: fixed;
    bottom: 0px;
    z-index: 10;
    width: 100%;
    
    a {
        text-decoration: none;
    }

    .soporte {
        text-decoration: none;
        color: #2A2F7A;
        margin-right: 30px;
        display: flex;

        a {
            margin-left: 0.25rem;
        }
    }

    .instagram {
        text-decoration: none;
        color: #2A2F7A;
        margin-right: 30px;
        font-size: 20px;
    }

    .edowie-llc {
        color: #2A2F7A;
    }
}

.footer-position-fixed {
    position: fixed;
}
