.particular-experiences {
    position: relative;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 70px;
    width: 100%;
    overflow: hidden;
    border-radius: 0.3rem;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .hired-concretized-experience {
        text-align: center;
        font-size: x-large;
        margin-top: 30px;
        margin-bottom: 20px;
    }

    .description-experience {
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;

        .label {
            white-space: pre-line;
            font-weight: 500;
            font-size: 14px;
        }
    }

    .calendar {
        padding-left: 30px;
    }

    @media screen and (max-width: 768px) {
        .calendar {
            padding-left: 15px;
            padding-bottom: 20px;
        }
    }

    .details {
        display: grid;
        gap: 1rem;
        grid-auto-flow: dense;
        grid-template-columns: repeat(auto-fit, minmax(5rem, 1fr));

        // .references {
        //     grid-column: span 2;
        // }

        //.calendar {
            //grid-column: span 7;
       //}

        // .particualr-experience-hire {
        //     grid-column: span 3;
        // }
    }

    .date-picker-container {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
        margin-top: 40px;

        .date-button {
            background-color: #2A2F7A;
            border-color: #2A2F7A;
            color: white;
            border: 1px solid transparent;
            border-radius: 0.25em;
            display: inline-block;
            font-size: 1em;
            font-weight: 400;
            line-height: 1.5;
            padding: 0.4em 0.65em;
            text-align: center;
            user-select: none;
            vertical-align: middle;
        }

        .change-week-legend {
            font-size: small;
            margin-left: 10px;
        }

        .user-information {
            display: flex;
            margin-right: 20px;

            .country-logged-user {

                .container {
                    display: flex;

                    .flag {
                        border-radius: 50% !important;
                        width: 35px;
                        height: 35px;
                    }

                    .country {
                        font-weight: 600;
                        padding-left: 0.5rem;
                        margin-top: 0.25rem;
                        line-height: 28px;
                    }
                }
            }

            .time-use {
                font-weight: 600;
                padding-left: 0.5rem;
                margin-top: 0.4rem;
            }
        }
    }


    .no-active {
        padding-bottom: 0;
        margin-bottom: 3rem;
        margin-top: 3rem;

        .title {
            text-align: center;
            font-weight: 400;
            display: block;
            font-size: 25px;
        }
    }
}
