.group-experience-item {
    .group-experience-item-card {
        padding: 0;
        margin-bottom: 1.5rem;
        margin-right: 0.25rem;
        overflow: hidden;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
        width: 300px;

        .group-experience-item-card-cover-image {
            overflow: hidden;
            margin-bottom: 1rem;
            width: 100%;

            a {
                position: relative;
                display: block;
                
                img {
                    transition: all 0.4s ease;
                    position: relative;
                    height: 200px;
                    width: 100%;
                }
            }
        }

        .group-experience-item-card-details {
            padding-top: 0;
            flex: 1 1 auto;
            min-height: 1px;
            padding: 1.25rem;
            word-wrap: break-word;

            .group-experience-item-card-details-subcategory {
                font-weight: 700;
                color: #E50202;
                text-transform: uppercase;
                padding-left: 1rem;
                padding-right: 1rem;
                margin-right: 0.25rem;
                display: inline-block;
                border-radius: 0.3rem;
                background-color: #facccc;
                border-color: #f8b8b8;
                font-size: 10px;
                line-height: 32px;
                letter-spacing: 0.6px;
            }

            .group-experience-item-card-details-price {
                font-weight: 700;
                color: #10d876;
                padding-left: 1rem;
                float: right;
                font-size: 16px;
                line-height: 32px;
                letter-spacing: 0.6px;

                span {
                    font-size: 10px
                }
            }

            .group-experience-item-card-details-title {
                font-weight: 700;
                margin-top: 1rem;
                font-size: 16px;
                line-height: 28px; 

                a {
                    color: #212529;
                    text-decoration: none;
                }
            }

            .group-experience-item-card-details-description {
                font-weight: 600;
                color: #adb5bd;
                margin-top: 0.5rem;
                margin-left: 0;
                font-size: 12px;
            }

            .group-experience-item-card-details-registrations {
                position: relative;
                margin-top: 1rem;
                margin-bottom: 0.5rem;
                margin-left: 0;
                display: block;
                padding-left: 0;

                li {
                    display: inline-block;
                    float: none;
                    position: relative;
                    width: 15px;

                    .no-user {
                        display: inline-block;
                        width: 30px;
                    }
                }

                .last-member a {
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    border-radius: 30px;
                    line-height: 30px;
                    font-weight: 600;
                    color: #adb5bd;
                    text-align: center;
                    font-size: 12px;
                    letter-spacing: 1px;
                    background-color: #f5f5f5;
                    text-decoration: none;
                }

                .registered {
                    padding-left: 1.5rem;
                    width: auto;

                    a {
                        font-weight: 500;
                        color: #adb5bd;
                        font-size: 12px;
                        text-decoration: none;
                    }
                }
            }

            .group-experience-item-card-details-buttons {
                display: flex;
                width: 100%;
                justify-content: flex-end;

                .group-experience-item-card-details-buttons-button {
                    padding-bottom: 0.5rem;
                    padding-top: 0.5rem;
                    height: auto;
                    text-decoration: none;

                    i {
                        color: #2A2F7B;
                        margin-right: 1rem;
                        font-size: 20px;
                    }
                }
            }

            .remove-button {
                border: 0;
                background: transparent;
                padding-right: 0;

                i {
                    margin-right: 0 !important;
                    color: red !important;
                }
            }

            .delete-discount-button {
                border: 0;
                background: transparent;
                color: red;
                margin-right: 1rem;
                font-size: 20px;
            }
        }
    }
}

.group-experience-modal {
    width: 520px !important;
    margin-left: 35% !important;
    margin-top: 15% !important;

    .group-experience-modal-content {

    }
}