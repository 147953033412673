.discount {
    padding-top: 10px;

    @media screen and (min-width: 991px) {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 80px;
        margin-bottom: 60px;
    }

    .discount-card {
        padding: 0;
        margin-bottom: 1.5rem;
        width: 100%;
        border: 0;
        background-color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        border-radius: 0.25rem;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03);

        .discount-header {
            padding: 1.5rem;
            width: 100%;
            display: flex;
            border-radius: 0.3rem;
            border: 0;
            flex: 1 1 auto;
            min-height: 1px;
            background-color: #2A2F7A;

            .discount-back {
                margin-top: 0.5rem;
                display: inline-block;
                text-decoration: none;
                outline: none;

                i {
                    color: #fff;
                    font-size: 20px;
                }
            }

            h4 {
                font-weight: 600;
                color: #fff;
                margin-left: 1.5rem;
                margin-top: 0.5rem;
                margin-bottom: 0;
                font-size: 18px;
            }
        }

        .discount-legend {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 0;
            width: 100%;
            font-size: 14px;
        }

        .discount-form {
            padding: 1.5rem;
            margin-bottom: 0;
            width: 100%;
            border: 0;
            flex: 1 1 auto;
            min-height: 1px;

            .save-button {
                display: flex;
                justify-content: flex-end;
                margin-top: 25px;
            }

            .video-required {
                margin-left: 10px;
            }
        }
    }
}
