.my-notifications {
    padding-top: 70px;
    padding-bottom: 70px;
    
    .my-notifications-header {
        padding: 10px 0px;
        border-width: 0;
        border-radius: 0;
        border-bottom: 1px solid #ebebea;

        .my-notifications-title {
            margin-bottom: 0px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 700;
            text-transform: uppercase;
        }
    }

    .my-notifications-body {
        list-style-type: none;
        padding-left: 0;
        padding-top: 30px;
    }
}

@media screen and (max-width: 768px) {
    .my-notifications {
        padding-top: 0px;
        padding-bottom: 0px;
        margin: 10px;
    }
}