.file-viewer {
    margin-top: 10px;
    margin-left: 20px;
    width: 100%;
    margin-bottom: 25px;

    .image-without-file {
        height: 500px;
        width: 100%;
    }

    .image-audio-file {
        height: 400px;
        width: 100%;
    }

    #react-doc-viewer {
        overflow: scroll;
        height: 500px;
    }
}