.dataCard {
    //background-color: #efefef;
    border-radius: 0.5em;
    margin-top: 30px;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    padding: 1em;
  }
  
  .customerCard {
    width: 100%;
    height: 20rem;
  }