.manage-service-particular-experience {
    padding: 15px;
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .title {
        padding-bottom: 0;
        margin-bottom: 1rem;
        min-height: 1px;
        padding: 1.25rem;

        h2 {
            font-weight: 400;
            display: block;
            font-size: 25px;
        }
    }
}