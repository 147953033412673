.enroll-section-card {
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    margin-left: 20px;
    border-radius: 0.3rem;
    border: 0;
    background-color: #1E74FD;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    margin-right: 20px;
    
    .card-details {
        flex: 1 1 auto;
        min-height: 1px;
        padding: 1.25rem;

        .price-container {
            display: flex;
            justify-content: space-between;

            .price-label {
                font-weight: 700;
                color: #fff;
                font-size: 15px;
                letter-spacing: 1px;
            }

            .price {
                font-weight: 700;
                color: #fff;
                font-size: 44px;
            }

        }

        .total-price-container {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            margin-bottom: 20px;

            .total-price-label {
                font-weight: 700;
                color: #fff;
                font-size: 35px;
                text-transform: uppercase;
            }

            .total-price {
                font-weight: 700;
                color: #fff;
                font-size: 35px;
                text-transform: uppercase;
            }
        }

        .price-without-discount {
            font-size: 15px;
            line-height: 24px;
            color: #fff;
            font-weight: 700;
        }

        .price-with-discount-container {
            display: flex;
            justify-content: space-between;

            .price-with-discount-legend {
                font-size: 15px;
                color: #fff;
                font-weight: 700;
            }
        }
        
        .description {
            font-weight: 500;
            color: #fff;
            margin-bottom: 1.5rem;
            font-size: 12px;
            line-height: 24px;
        }

        .checks-item {
            color: #fff;
            margin-bottom: 0.5rem;
            font-size: 12px;
            font-weight: 400;

            i {
                color: #fff;
                margin-right: 0.5rem;
            }
        }

        .buy-button {
            font-weight: 600;
            color: #1E74FD;
            padding: 1rem;
            margin-top: 0.5rem;
            width: 100%;
            display: inline-block;
            border-radius: 0.3rem;
            border: 0;
            background-color: #fff;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            user-select: none;
            line-height: 1.5;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            font-size: 12px;
        }
    }
}
