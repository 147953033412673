@mixin placeholder-color ($color) {
    &::-webkit-input-placeholder { /* WebKit, Blink, Edge */
      color: $color;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
     color: $color;
     opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
     color: $color;
     opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
     color: $color;
   }
  }

.form-fields {
    .field-group {
        margin-left: 0;
        padding-left: 0;

        .field-item {
            border: 1px transparent;
            //padding: 6px 0 0;
            position: relative;
            display: inline-block;
            margin-left: 0;
            margin-bottom: 6px;
            text-align: left;
            float: left;
            width: 100%;


            .field-item--50 {
                width: 50%;
            }

            .field-item--100 {
                width: 100%;
            }

            .field-item--70 {
                width: 70%;
            }

            .field-item--30 {
                width: 30%;
            }

            .field-item--pr-10 {
                padding-right: 10px;
            }

            label {
                width: 100%;
                display: inline-block;
                margin-bottom: 5px;
                padding: 0;
                font-weight: 700;
                font-size: 13px;
            }

            input:not([type=checkbox]) {
                padding: 10px 12px;
                height: 40px;
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 3px;
                background-color: #fff;
                font-size: 13px;
                line-height: 20px;
            }

            input[type="text"]:focus {
                outline: none;
                border: 1px solid #2A2F7A;
                box-shadow: inset 0 0 0 1px #2A2F7A;
            }

            textarea {
                padding: 10px 12px;
                width: 100%;
                border: 1px solid #ccc;
                border-radius: 3px;
                background-color: #fff;
                font-size: 13px;
                line-height: 20px;
            }

            textarea:focus {
                outline: none;
                border: 1px solid #2A2F7A;
                box-shadow: inset 0 0 0 1px #2A2F7A;
            }

            select {
                height: 40px;
                color: #666;
                border: 1px solid #ccc;
                width: 100%;
                background-color: #fff;
                border-radius: 3px;
                font-size: 13px;
                padding: 0 40px 0 10px;
                text-align: left;
                line-height: 40px;
                cursor: pointer;
                -moz-appearance: none;
                -webkit-appearance: none;
                appearance: none;
                box-shadow: 0 0 2px rgba(0,0,0,.1);
            }

            .register-select-disabled {
                background-color: darkgray !important;
            }

            select:focus {
                outline: none;
                border: 1px solid #2A2F7A;
                box-shadow: inset 0 0 0 1px #2A2F7A;
            }

            .dropdown-arrow {
                right: 18px;
                top: 50px;
                border-style: solid;
                height: 0;
                width: 0;
                border-width: 7px 5.5px 0;
                border-color: #999 transparent transparent transparent;
                position: relative;
                z-index: 10;
                pointer-events: none;
            }

            .checkbox {
                margin-top: 7px;

                label {
                    margin-left: 25px;
                    width: auto;
                    font-weight: 400;
                    vertical-align: middle;
                }

                input[type=checkbox] {
                    cursor: pointer;
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    left: 3px;
                    top: 5px;
                }

                input[type=checkbox]:checked ~ .checkmark:after {
                    display: block;
                }

                .checkmark:after {
                    content: "";
                    position: absolute;
                    left: 5px;
                    width: 5px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                }

                .checkmark {
                    position: absolute;
                    top: 3px;
                    left: 3px;
                    height: 16px;
                    width: 16px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    background-color: #fff;
                    pointer-events: none;
                }

                input[type=checkbox]:checked ~ .checkmark {
                    border-color: #2A2F7A;
                    background-color: #2A2F7A;
                }
            }
            /*Configuration item */
            .configuration-item {
                border-top: 1px dotted #eee;
                border-bottom: 1px dotted #eee;
                position: relative;
                padding: 10px 0px;

                label {
                    margin-left: 0px;
                    width: auto;
                    font-weight: 400;
                    vertical-align: middle;
                }

                input[type=checkbox] {
                    cursor: pointer;
                    position: absolute;
                    width: 14px;
                    height: 14px;
                    right: 3px;
                    top: 15px;
                }

                input[type=checkbox]:checked ~ .checkmark:after {
                    display: block;
                }

                .checkmark:after {
                    content: "";
                    position: absolute;
                    right: 5px;
                    width: 5px;
                    height: 10px;
                    border: solid #fff;
                    border-width: 0 3px 3px 0;
                    transform: rotate(45deg);
                }

                .checkmark {
                    position: absolute;
                    top: 15px;
                    right: 3px;
                    height: 16px;
                    width: 16px;
                    border: 1px solid #ccc;
                    border-radius: 3px;
                    background-color: #fff;
                    pointer-events: none;
                }

                input[type=checkbox]:checked ~ .checkmark {
                    border-color: #2A2F7A;
                    background-color: #2A2F7A;
                }
            }

            input:not([type=checkbox]):disabled {
                background-color: #eaeaea;
                border: 1px solid #ccc;
                color: #666;
                cursor: default;
            }

            textarea:disabled {
                background-color: #eaeaea;
                border: 1px solid #ccc;
                color: #666;
                cursor: default;
            }

            select:disabled {
                background-color: #eaeaea;
                border: 1px solid #ccc;
                color: #666;
                cursor: default;
            }

            button:disabled {
                background-color: #eaeaea;
                border: 1px solid #ccc;
                color: #666;
                cursor: default;

                i {
                    color: #666;
                }
            }

            .error input, .error textarea, .error select {
                border: 1px solid #e34f45;
            }

            .error-form p {
                color: #e34f45;
                font-size: 13px;
                text-align: left;
                margin: 0;
            }

            .submit-button {
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                padding: 0.5rem;
                border: 1px solid transparent;
                background-color: #2A2F7A;
                font-size: 10px;
                border-radius: 10px;
                line-height: 24px;
                letter-spacing: 1px;
                width: 100px;
                height: 45px;
                margin-top: 10px;

                .button--loading {
                    padding-left: 3em;
                    position: relative;
                    display: flex;
                    align-items: center
                }

                .button--loading:after {
                    content: "";
                    width: 1.5em;
                    height: 1.5em;
                    position: absolute;
                    border-radius: 50%;
                    border: 5px solid rgba(255,255,255,.3);
                    border-top-color: #fff;
                    animation: dp-preloader-spin .7s infinite linear
                }

                .button--loading:after {
                    left: 1em
                }

                .button--loading:after {
                    border-color: rgba(204,204,204,.3);
                    border-top-color: #ccc;
                    left: 9px
                }
            }

            .icon-input {
                position: relative;
                color: white;

                i {
                    position: absolute;
                    left: 15px;
                    top: 19px;
                }

                .input {
                    height: 60px;
                    line-height: 60px;
                    display: block;
                    border: 2px #eee solid;
                    border-radius: 7px;
                    background-color: transparent;
                    padding-left: 3rem;
                    letter-spacing: 1px;
                    font-size: 16px;
                    background-color: transparent;
                    color: white;
                    @include placeholder-color(white);
                    
                }

                .flag-select {
                    color: black;
                    width: 100%;
                    
                    #rfs-btn {
                        padding-left: 3rem;
                        border: 2px #eee solid;
                        letter-spacing: 1px;
                        font-size: 16px !important;
                        line-height: 35px;
                        height: 60px;

                        span {
                            color: white;
                        }

                        &[aria-expanded="true"]:after {
                            border-top: 0;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 5px solid white;
                        }

                        &:after {
                            border-top: 5px solid white;
                            border-left: 5px solid transparent;
                            border-right: 5px solid transparent;
                            border-bottom: 0;
                        }
                    }

                    [name*="rfs-q"] {
                        width: -webkit-fill-available;
                    }

                    ul {
                        li  {
                            font-weight: 400;
                        }
                    }
                }

                .select {
                    height: 60px;
                    line-height: 60px;
                    display: block;
                    border: 2px #eee solid;
                    border-radius: 7px;
                    background-color: transparent;
                    padding-left: 3rem;
                    letter-spacing: 1px;
                    font-size: 16px;
                    background-color: transparent;
                    color: white;
                    @include placeholder-color(white);
                }

                .dropdown-arrow {
                    top: 28px;
                    border-color: white transparent transparent transparent;
                }
            }
        }
    }
}
