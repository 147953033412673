.forgot {
    display: flex;
    height: 100vh;

    .forgot-card {
        margin-left: 20px;
        background-color: initial;
        margin-top: 50%;
        min-width: 380px;
        overflow: hidden;
        border: 0;
        box-shadow: none ;
        width: 100%;

        .forgot-card-body {
            flex: 1 1 auto;

            .forgot-card-body-title {
                color: #fff;
                font-size: 1.25rem;
                font-weight: 700;
                margin-bottom: 1rem;
            }
        }

        .input {
            background-color: transparent;
            border: 2px white solid;
            color: white !important;
        }

        i {
            color: white;
        }

        ::placeholder { /* Most modern browsers support this now. */
            color: white;
        }

        .btn-recover-password {
            background-color: #4EBCBC;
            text-align: center;
            color: #fff;
            font-weight: 600;
            padding: 0px;
        }
    }

}

.overflow-hidden {
    overflow: hidden !important;
}

.forgot-background {
    background: url(../../../general/images/login_background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@media screen and (max-width: 768px) {
    .forgot-card {
        margin-left: 80px !important;
        margin-top: 55% !important;
    }
}