.group-experiences-menu {
    background-color: #fbfcfe;
    padding-top: 70px;
    display: block;
    margin-bottom: 60px;
    
    .group-experiences-menu-title {
        margin-top: 15px;
        margin-bottom: 15px;
        font-weight: 400;
        font-size: 1.5rem;
        margin-left: 25px;
    }

    .card-group-experience {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;

        .card-group-experience-body {
            padding: 0;
            margin-right: 0.25rem;
            width: 100%;
            overflow: hidden;
            border-radius: 0.3rem;
            border: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
            margin-bottom: 20px;

            .card-group-experience-image {
                height: 270px;
                margin-bottom: 0;
                width: 100%;
                float: left;
                padding: 10px;

                img {
                    max-height: 250px;
                    width: 100%;
                }
            }

            .card-group-experience-details {
                padding-top: 1rem;
                padding-left: 0.5rem;
                padding-right: 0.25rem;
                padding-bottom: 0;
                width: 100%;
                float: left;

                .tags {
                    .tag {
                        font-weight: 700;
                        text-transform: uppercase;
                        padding-left: 1rem;
                        padding-right: 1rem;
                        margin-bottom: 0.25rem;
                        margin-right: 0.25rem;
                        display: inline-block;
                        border-radius: 0.3rem;
                        font-size: 10px;
                        line-height: 32px;
                        letter-spacing: 0.6px;
                    }

                    .group-experience-tag {
                        background-color: #facccc;
                        border-color: #f8b8b8;
                        color: #E50202
                    }
                }

                .qualification {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 0.5rem;
                    padding-top: 5px;

                    .stars {
                        display: flex;
                        height: 12px;
                        text-align: left;
                        margin-right: 0.5rem;
                        margin-top: 0.25rem;

                        .star {
                            width: 12px;
                        }
                    }

                    .reviews {
                        font-weight: 600;
                        color: #adb5bd;
                        font-size: 12px;

                        p:last-child {
                            margin-bottom: 0;
                        }
                    }
                }

                .title {
                    font-weight: 700;
                    margin-top: 1rem;
                    font-size: 16px;
                    line-height: 28px;

                    a {
                        color: #212529;
                        text-decoration: none;
                    }
                }

                .item {
                    font-weight: 700;
                    color: #212529;
                    margin-left: 0;
                    display: inline-block;
                    font-size: 12px;
                }

                .dot {
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    width: 8px;
                    height: 8px;
                    border-radius: 8px;
                    display: inline-block;
                    line-height: 25px !important;
                    text-align: center;
                    background-color: #eee !important;
                }

                .description {
                    font-weight: 600;
                    color: #adb5bd;
                    margin-top: 1rem;
                    margin-left: 0;
                    font-size: 12px;
                }

                .card-details-user-profile {
                    margin-bottom: 0.5rem;
                    margin-top: 0.5rem;
                    text-decoration: none;

                    .ambassador {
                        margin-top: 10px;
                        margin-left: 5px;
                        width: 30px;
                        height: 30px;
                    }

                    img {
                        width: 30px;
                        height: 30px;
                        margin-bottom: 0.5rem;
                        margin-top: 0.5rem;
                        border-radius: 50%;
                    }

                    span {
                        font-weight: 700;
                        color: #212529;
                        margin-left: 0.5rem;
                        display: inline-block;
                        font-size: 12px;
                    }
                }

                .free-price {
                    display: flex;
                    justify-content: flex-end;
                    padding-right: 20px;

                    .button {
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 0.5rem;
                        margin: 0.5rem;
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                        cursor: pointer;
                        user-select: none;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        border-radius: 30px;
                        font-size: 10px;
                        line-height: 24px;
                        letter-spacing: 1px;
                        background-color: #2A2F7A;
                        width: 110px;
                    }
                }

                .price-card {
                    text-align: center;

                    .free {
                        color: #10d876;
                        font-size: 15px;
                        line-height: 30px;
                        margin-bottom: 0;
                        text-transform: none;
                        display: inherit;
                        height: auto;
                    }

                    .price-without-discount{
                        font-size: 14px;
                        line-height: 24px;
                        color: #2A2F7A;
                        text-decoration: line-through;
                        font-weight: 700;
                    }

                    .price-with-discount {
                        font-size: 15px;
                        line-height: 30px;
                        margin-bottom: 0;
                        text-transform: none;
                        font-weight: 700;
                        display: inherit;
                        height: auto;
                        color: #212121;
                    }
                }

                .group-experience-item-card-details-registrations {
                    position: relative;
                    margin-top: 1rem;
                    margin-bottom: 0.5rem;
                    margin-left: 0;
                    display: block;
                    padding-left: 0;
    
                    li {
                        display: inline-block;
                        float: none;
                        position: relative;
                        width: 15px;
    
                        .no-user {
                            display: inline-block;
                            width: 30px;
                        }
                    }
    
                    .last-member a {
                        width: 30px;
                        height: 30px;
                        display: inline-block;
                        border-radius: 30px;
                        line-height: 30px;
                        font-weight: 600;
                        color: #adb5bd;
                        text-align: center;
                        font-size: 12px;
                        letter-spacing: 1px;
                        background-color: #f5f5f5;
                        text-decoration: none;
                    }
    
                    .registered {
                        padding-left: 1.5rem;
                        width: auto;
    
                        a {
                            font-weight: 500;
                            color: #adb5bd;
                            font-size: 12px;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .no-data-to-show {
            margin-top: 7px;
            padding-top: 20px;
            font-weight: 400;
            font-size: 15pt;
            height: 60px;
            text-align: center;
            display: block;
            background-color: #fff;
          }
    }
}

@media screen and (max-width: 768px) {
    .group-experiences-menu {
        padding-top: 0px;
    }
}