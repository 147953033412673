.file-card {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .file-inputs {
        position: relative;
        margin-bottom: 1.5em;
        width: 100%;

        input {
            position: relative;
            text-align: right;
            opacity: 0;
            //z-index: 2;
            cursor: pointer;
            height: 46px;
            max-width: 100%;
        }

        button {
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            //z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            background-color: #2A2F7A;
            font-size: 1.1rem;
            cursor: pointer;
            border-radius: 10px;
            border: none;
            outline: none;
            // padding: 1em;
            transition: background-color 0.4s;
            box-shadow: 0px 8px 24px rgba(149, 157, 165, 0.5);

            i {
                width: 1.5em;
                height: 1.5em;
                padding: 0.4em;
                background-color: #fff;
                color: #2A2F7A;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 0.8em;
                font-size: 0.8em;
            }
        }

        &:hover {
            button {
                background-color: #2A2F7A;
            }
        }
    }

    .progress-section {
        width: 100%;
        margin-top: 5px;
    }
}

.main {
    font-weight: bold;
    margin-bottom: 0.4em;
}

.info {
    font-size: 0.8rem;
}