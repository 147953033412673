.terms-and-conditions {
    padding-top: 70px;
    padding-bottom: 1.5rem;

    .terms-and-conditions-body
    {
        padding-right: 15px;
        padding-left: 15px;
        margin-right: 10rem;
        margin-left: 10rem;

        .terms-and-conditions-title {
            font-weight: 700;
            margin-bottom: 0.5rem;
            padding-bottom: 1rem;
            display: block;
            font-size: 22px;
            line-height: 1.6;
            margin-top: 1.5rem;
        }

        .terms-and-conditions-card {
            padding: 1.5rem;
            overflow: hidden;
            display: block;
            border-radius: 0.3rem;
            border: 0;
            position: relative;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

            .section {
                padding-left: 0.5rem;
                margin-top: 1.5rem;

                .section-title {
                    font-weight: 700;
                    margin-bottom: 1rem;
                    margin-top: 0.25rem;
                    font-size: 20px;

                    .btn-link {
                        border: 0;
                        background-color: transparent;
                        text-decoration: none;
                        font-weight: 700;

                        &:hover {
                            color: black;
                        }
                    }
                }

                .section-subtitle {
                    color: #6c757d;
                    padding-left: 0.5rem;
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 28px;
                    font-weight: 700;
                }

                .section-legend {
                    color: #6c757d;
                    padding-left: 0.5rem;
                    margin-bottom: 0;
                    font-size: 12px;
                    line-height: 28px;
                }

                .pt-15 {
                    padding-top: 1.5rem;
                }

                .pt-05 {
                    padding-top: 0.5rem;
                }
            }
        }

        .register-back-button {
            padding-top: 1.5rem;

            a {
                width: 120px;
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                padding: 0.5rem;
                margin-top: 0.5rem;
                margin-left: 0.25rem;
                display: inline-block;
                text-align: center;
                vertical-align: middle;
                cursor: pointer;
                user-select: none;
                border: 1px solid transparent;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                border-radius: 30px;
                font-size: 10px;
                line-height: 24px;
                letter-spacing: 1px;
                background-color: #2A2F7A;
                text-decoration: none;
                outline: none;
            }
        }
    }   
}

@media screen and (max-width: 768px) {
    .terms-and-conditions {
        padding-top: 0px;

        .terms-and-conditions-body {
            padding-right: 0px;
            padding-left: 0px;
            margin-left: 15px;
            margin-right: 15px;
        }
    }
}