.message-recipient {
    border-radius: 5px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    padding: 10px;
    margin-bottom: 20px;
    list-style-type: none;

    .message-recipient-user {
        width: 100%;

        .message-recipient-user-button {
            border: 0;
            background-color: transparent;
            display: flex;
            justify-content: left;
            color: #999;
            font-weight: 700;
            font-size: 13.6px;
            
            img {
                top: 0;
                left: 0;
                border-radius: 50%;
                width: 55px;
                height: 55px;
                border-radius: 50%;
            }
        }

        .message-recipient-user-details {
            padding-left: 10px;
            display: inline-grid;
            vertical-align: middle;
            padding-top: 7px;

            .message-recipient-user-details-last-access {
                font-weight: 600;
                display: flex;
                justify-content: flex-start;
                font-size: 10px;
            }
        }
    }

    .message-recipient-unread-messages {
        padding-top: 2px;
        border: 1px solid lightgreen;
        border-radius: 50%;
        background-color: lightgreen;
        width: 30px;
        height: 30px;
        text-align: center;
        color: white;
        font-weight: 700;
    }
}