.my-notifications-body-item {
    border-radius: 5px;
    box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
    padding: 10px;
    margin-bottom: 20px;
    background-color: #fff;

    .my-notifications-body-item-link {
        text-decoration: none;
        cursor: pointer;

        .my-notifications-body-item-card {
            cursor: pointer;
            margin-bottom: 0.25rem;
            width: 100%;
            border: 0;
            position: relative;
            display: flex;
            flex-direction: column;
            min-width: 0;
            word-wrap: break-word;
            background-color: #fff;
            background-clip: border-box;
            border-radius: 0.25rem;

            .my-notifications-body-item-card-user-photo {
                width: 50px;
                height: 50px;
                position: absolute;
                border-radius: 50%;
                left: 0px;

            }

            .my-notifications-body-item-card-details {
                margin-left: 60px;
                padding-top: 0.5rem;
                font-weight: 500;
                color: #adb5bd;
                font-size: 13px;
                line-height: 1.6;
            }

            .my-notifications-body-item-card-footer {
                display: -webkit-box;
                display: flex;
                margin-top: 23px;
                padding: 13px 8px 0;
                color: #999;
                border-top: 1px solid #ebebea;

                span {
                    margin-left: auto;
                    font-size: 13px;
                    
                    i {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}