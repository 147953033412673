.language-selector {
    height: 34px;
    width: 63px;
    margin-top: -3px;
    margin-right: 25px;

    .language-selector-dropdown-toggle {
        display: flex;
        border: 0;
        background-color: transparent;

        img {
            width: 30px;
            height: 30px;
        }

        span {
            color: #2A2F7A;
            margin-left: 7px;
        }
    }

    .language-selector-dropdown-toggle:focus {
        box-shadow: 0 0 0 0.1rem white, 0 0 0 0.25rem #2A2F7A;
    }

    .dropdown-toggle::after {
        color: #2A2F7A;
        margin-top: 10px;
    }

    .dropdown-menu {
        .dropdown-menu-language-container {
            display: flex;
            justify-content: space-between;
            width: 50px;
        }
    }
}