.description-section {
    width: 100%;
    margin-left: 20px;
    padding: 1.5rem;
    margin-top: 0.5rem;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .label {
        font-weight: 700;
        padding-left: 0.25rem;
        margin-bottom: 1rem;
        margin-top: 0.25rem;
        font-size: 20px;
    }

    .description {
        padding-left: 0.25rem;
        white-space: pre-line;
        font-weight: 500;
        color: #6c757d;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 28px;
    }
}

.view-content-tabs {
    .description-section {
        margin-left: 0px;
    }
}