.searcher {
    border: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 15px;
    background-repeat: no-repeat;
    margin: 20px;

    .body {
        padding: 1.5rem;
        width: 100%;
        flex: 1 1 auto;
        min-height: 1px;

        .form {
            padding-left: 25px;
            padding-right: 25px;
            border-radius: 0.3rem;
            border: 1px solid #E1E1F0;
            background-color: #fff;

            .field-item {
                float: none;

                .dropdown-arrow {
                    top: 2.5em;
                }

                .find-button {
                    display: flex;
                    justify-content: flex-end;
                    margin-top: 10px;
                    width: 100%;

                    .submit-button {
                        width:100%;
                    }
                }

                @media screen and (max-width: 500px) {
                    .find-button {
                        //margin-top: 0px;
                        //display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }
}
