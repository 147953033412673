.chat-message-form {
    .intput-with-button-item {
        display: flex !important;
        margin-top: 10px;
    }

    .send-message-section {
        padding: 0;

        .send-button {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            border: 1px solid transparent;
            background-color: #2A2F7A;
            font-size: 10px;
            border-radius: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            height: 40px;
            margin-top: 23px;
            width: 40px;
        }
    }
}