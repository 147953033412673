.promocodes {
    padding-top: 10px;

    @media screen and (min-width: 991px) {
        max-width: 800px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 80px;
        margin-bottom: 60px;
    }

    .promocodes-card {
        padding: 0;
        margin-bottom: 1.5rem;
        width: 100%;
        border: 0;
        background-color: #fff;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        border-radius: 0.25rem;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.03);

        .promocodes-header {
            padding: 1.5rem;
            width: 100%;
            display: flex;
            border-radius: 0.3rem;
            border: 0;
            flex: 1 1 auto;
            min-height: 1px;
            background-color: #2A2F7A;

            .promocodes-back {
                margin-top: 0.5rem;
                display: inline-block;
                text-decoration: none;
                outline: none;

                i {
                    color: #fff;
                    font-size: 20px;
                }
            }

            h4 {
                font-weight: 600;
                color: #fff;
                margin-left: 1.5rem;
                margin-top: 0.5rem;
                margin-bottom: 0;
                font-size: 18px;
            }


        }

        .promocodes-legend {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
            padding-top: 1.5rem;
            padding-bottom: 0;
            width: 100%;
            font-size: 14px;
        }

        .promocodes-form {
            padding: 1.5rem;
            margin-bottom: 0;
            width: 100%;
            border: 0;
            flex: 1 1 auto;
            min-height: 1px;
            padding-top: 0;
            
            .save-button {
                display: flex;
                justify-content: flex-end;
                margin-top: 25px;
            }
        }

        .promocodes-grid {
            .promocodes-grid-header {
                background-color: #2A2F7A;
                padding: 1rem;
                width: 100%;
                border-radius: 0.3rem;
                border: 0;
    
                .promocodes-grid-header-title {
                    font-weight: 600;
                    color: #fff;
                    margin-left: 1.5rem;
                    margin-top: 0;
                    margin-bottom: 0;
                    font-size: 18px
                }
            }

            .promocodes-tags {
                margin-top: 20px;
                margin-bottom: 20px;
                margin-left: 15px;

                .promocodes-tags-tag {
                    border-radius: .3rem;
                    display: inline-block;
                    font-size: 10px;
                    font-weight: 700;
                    letter-spacing: .6px;
                    line-height: 32px;
                    margin-right: 10px;
                    padding-left: 10px;
                    padding-right: 10px;
                    margin-top: 10px;
                    text-transform: uppercase;
                    background-color: #FF7F50;
                    border-color: #ffe1c5;
                    color: white;
                    cursor: pointer;
                }
            }
        }

        .promocodes-buttons {
            display: flex;
            justify-content: flex-end;
            margin-top: 25px;
            margin-bottom: 25px;
            margin-right: 15px;

            .save-code-button {
                font-weight: 700;
                color: #fff;
                text-transform: uppercase;
                padding: 0.5rem;
                border: 1px solid transparent;
                background-color: #2A2F7A;
                font-size: 10px;
                border-radius: 10px;
                line-height: 24px;
                letter-spacing: 1px;
                width: 100px;
                height: 45px;
                margin-top: 10px;
            }
        }
    }
}
