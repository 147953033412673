.main {
    display: flex;
    height: 100vh;
}

.overflow-hidden {
    overflow: hidden !important;
}

.image-login {
    background: url(../../../general/images/login_background.png) no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.login-card {
    min-width: 380px;
    /*max-width: 400px;*/
    margin-top: 20%;
    overflow: hidden !important;
    background-color: transparent !important;
}

.login-card h1 {
    color: white;
    font-size: 1.5rem;
}

.login-card h2 {
    color: white;
    font-size: 1.25rem;
}

.login-card h6 {
    color: white;
}

.login-card i {
    color: white;
}

.login-card input {
    background-color: transparent;
    border: 2px white solid;
    color: white !important;
}

.login-card ::placeholder { /* Most modern browsers support this now. */
    color: white;
}

.login-card a { /* Most modern browsers support this now. */
    color: white;
}

    .login-card input:focus {
        color: #212529;
        background-color: transparent;
        border-color: white;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }

.btn-ingresar {
    background-color: #4EBCBC;
    text-align: center;
    color: #fff;
    font-weight: 600;
    padding: 0px;
}

.btn-forgot-password {
    font-weight: 600;
    color: #495057;
    float: right;
    font-size: 14px;
}

.btn-regiaster {
    font-size: 14px;
    line-height: 32px;
    color: #adb5bd;
    font-weight: 500 !important;
}