.my-profile {

    .header {
        height: 250px;
        margin-bottom: 1rem;
        width: 100%;
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

        .detalis {
            height: 100%;
            background: rgba(0, 0, 0, 0.6);

            .row-spacing {
                padding-left: 50px;

                .user-copy-clipboard {
                    display: flex;

                    a {
                        text-decoration: none;

                        .user-image-container {
                            overflow: hidden;
                            margin-right: auto;
                            margin-left: 1.5rem;
                            margin-top: 1.5rem;
                            margin-bottom: 0;
                            width: 90px;

                            .user-image {
                                width: 90px;
                                height: 90px;
                                padding: 0.25rem;
                                margin-bottom: 0.5rem;
                                float: right;
                                border-radius: 50%;
                                background-color: #fff;
                            }
                        }

                        .username {
                            font-weight: 700;
                            color: #fff;
                            margin-top: 1rem;
                            font-size: 22px;
                        }
                    }

                    .email {
                        font-weight: 600;
                        color: #adb5bd;
                        margin-left: 0;
                        display: inline-block;
                        font-size: 12px;
                    }

                    .profile-title {
                        color: #fff;
                        font-size: 14px;
                    }

                    .profile-value {
                        font-weight: 600;
                        color: #adb5bd;
                        margin-left: 3px;
                        display: inline-block;
                        font-size: 12px;
                    }

                    .copy-clipboard {
                        padding-top: 60px;
                        display: block;

                        button {
                            border: 0;
                            background-color: transparent;

                            i {
                                font-size: 32px;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}
