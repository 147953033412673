.relations {
    padding-top: 70px;
    
    .relations-header {
        padding-bottom: 0;
        margin-bottom: 1rem;
        min-height: 1px;
        padding: 1.25rem;

        .relations-title {
            font-weight: 400;
            display: block;
            font-size: 25px;
        }
    }

    .relations-tabs {
        position: relative;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        margin-bottom: 1.5rem;
        width: 100%;
        overflow: hidden;
        border-radius: 0.3rem;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
        height: auto;

        ul {
            justify-content: space-between;
            height: 100%;
            align-items: center;

            li a {
                transition: all 0.4s ease;
                font-weight: 700;
                text-transform: uppercase;
                color: #adb5bd;
                display: inline-block;
                font-size: 12px;
                padding-bottom: 2.5rem;
                padding-top: 2.5rem;
            }

            li a.active {
                border-bottom: 2px #333 solid !important;
                color: #000;
            }

            .nav-item:last-child {
                margin-right: 3rem;
            }

            .nav-item:first-child {
                margin-left: 3rem;
            }
        }

        .nav-tabs .nav-link {
            border: 0;
        }
    }
}

@media screen and (max-width: 768px) {
    .relations {
        padding-top: 0px;
        padding-bottom: 50px;
    }
}