header {
    position: fixed;
    width: 100%;
    z-index: 100000;
    background-color: white;
    height: 65px;

    a.navbar-brand {
        white-space: normal;
        text-align: center;
        word-break: break-all;
    }

    .logo-empresa {
        height: 55px;
        margin-top: -5px;
    }

    .box-shadow {
        box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
        height: 100%;
      }
}



html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}



.navbar-nav {
    display:flex;
}

.navbar-nav > li.active a i {
    color: #fff !important;
}

.navbar-nav .item {
    padding-top: 4px;
}

.navbar-nav li {
    padding-left: 10px;
    padding-right: 10px;
    margin: 0px;
}

.navbar-nav .font-xl {
    font-size: 28px !important;
}

.navbar-nav > li.active {
    background: linear-gradient(135deg, #2A2F7A, #09f) !important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.navbar-nav ul:last-child {
    margin-bottom: 0;
}


.navbar-nav > li.active a span {
    color: #fff !important;
}

.navbar-nav li a span {
    line-height: 1.25;
    font-weight: 600;
    font-size: 13px;
    color: #2A2F7A;
}

.navbar-nav li a i {
    color: #2A2F7A;
}

.navbar-nav li a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 52px;
    padding-right: 0px;
    color: #1E74FD;
    text-decoration: none;
}

.navbar-nav li .dot-count {
    position: relative;
    top: 0px;
    right: -15px;
    border-radius: 5px;
    width: 7px;
    height: 7px;
    line-height: 15px;
    text-align: center;
    display: inline-block;
    font-size: 10px;
    background-color: #FE9431 !important;
}

.menu-usuario {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 52px;
    padding-right: 10px;
    cursor: pointer;
    padding-top: 10px;

    .dropdown-arrow {
        right: -30px;
        border-style: solid;
        height: 0;
        width: 0;
        border-width: 6px 4.5px 0;
        border-color: #2A2F7A transparent transparent transparent;
        position: relative;
        z-index: 10;
        pointer-events: none;
        top: -20px;
    }
}

/* Dropdown Content (Hidden by Default) */
.menu-usuario-content {
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    width: 250px;
    padding: 25px 20px;
    margin-top: -6px;
    box-shadow: 0px 15px 125px rgb(0 0 0 / 22%);
    border-radius: 6px;
    z-index: 999;
    border: 0;
    position: absolute;
    top: 120%;
    right: 0px;
    text-align: left;
    transition: all 0.4s ease;
}

/* Links inside the dropdown */
.menu-usuario-content .link {
    display: flex !important;
    flex-direction: row !important;
    justify-content: left !important;
    color: #1E74FD;
    height: 40px !important;
}

/* Show the dropdown menu on hover */
.menu-usuario:hover .menu-usuario-content {
    visibility: visible;
    opacity: 1;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
.menu-usuario:hover .dropbtn {
    background-color: #3e8e41;
}

.menu-usuario .be-edowie {
    background: linear-gradient(135deg, #2A2F7A, #09f);
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    padding: 0.5rem;
    margin-top: 1rem;
    display: inline-block;
    border-radius: 0.3rem;
    border: 0;
    font-size: 12px ;
    line-height: 34px;
    letter-spacing: 1px;
    width: 200px;
    margin-left: 12px;
}

.notifications-details {
    visibility: hidden;
    opacity: 0;
    background-color: #fff;
    width: 300px;
    padding: 25px 30px;
    box-shadow: 0px 15px 125px rgba(0, 0, 0, 0.22);
    border-radius: 6px;
    z-index: 999;
    border: 0;
    position: absolute;
    top: 110%;
    right: 75px;
    text-align: left;
    transition: all 0.4s ease;
}

.notifications-details h4 {
    font-weight: 700;
    margin-bottom: 1.5rem;
    font-size: 18px;
    color: #111;
}

.notifications {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 52px;
    padding-right: 0px;
    color: #2A2F7A;
    cursor: pointer;

    span {
        line-height: 1.25;
        font-weight: 600;
        font-size: 13px;
    }
}

.notifications:hover .notifications-details {
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 768px) {
    header {
        position: relative;
    }

    .navbar-nav {
        background-color: white;
    }
}
