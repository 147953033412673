.purchase-summary-section {
    margin-left: 20px;
    padding: 10px;
    margin-top: 0.5rem;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    margin-right: 0px;

    .purchaseSummary {
        color: #08703d;
        position: relative;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
    }

    .go-to-library-button {
        text-align: center;

        a {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            margin-top: 0.5rem;
            margin-left: 0.25rem;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            user-select: none;
            border: 1px solid transparent;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border-radius: 30px;
            font-size: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 200px;
            background-color: #2A2F7A;
            text-decoration: none;
        }
    }
}
