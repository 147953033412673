.theme {
    margin-bottom: 1.5rem;
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    padding: 20px;

    .theme-save-section {
        display: flex;
        justify-content: flex-end;

        .theme-save-section-button {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            border: 1px solid transparent;
            font-size: 10px;
            border-radius: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 150px;
            height: 45px;
            
        }

        .theme-save-section-save-button {
            background-color: #2A2F7A;
        }

        .theme-save-section-cancel-button {
            margin-left: 20px;
            background-color: gray;
        }
    }
}