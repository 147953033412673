.pagination {
    display: flex;
    justify-content: center;
    padding-right: 0;

    --bs-pagination-color: #2A2F7A;
    --bs-pagination-active-bg: #2A2F7A;
    --bs-pagination-active-border-color: #2A2F7A;
    --bs-pagination-focus-color: #2A2F7A;
    --bs-pagination-hover-color: #2A2F7A;
    --bs-pagination-focus-box-shadow: 0 0 0 0;
  }
  
  .items {
    margin-bottom: 1rem;
  }