.upcoming-events {
    padding: 1rem;
    margin-bottom: 1rem;
    overflow: hidden;
    border: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border-radius: 15px;
    max-height: 250px;

    .upcoming-events-title-section {
        background-color: #2A2F7A;
        padding: 10px;
        width: 100%;
        display: flex;
        border-radius: 0.3rem;
        border: 0;
        justify-content: center;

        .upcoming-events-title {
            font-weight: 600;
            color: #fff;
            margin-bottom: 0;
            margin-top: 0;
            font-size: 18px
        }
    }

    .upcoming-events-counter-section {
        text-align: center;
        padding-top: 1rem;

        .upcoming-events-counter {
            font-size: 18pt;
        }

        .in-progress {
            color: #f32323 !important;
        }
    }

    .upcoming-events-date-section {
        text-align: center;
        padding-top: 0.25rem;

        .upcoming-events-date {
            font-size: 10pt;
        }
    }

    .upcoming-events-type-section {
        text-align: center;
        padding-top: 0.25rem;

        .upcoming-events-type {
            font-size: 13pt;
        }
    }

    .upcoming-events-connect-section {
        display: flex;
        justify-content: center;
        padding-top: 1rem;

        .upcoming-events-connect-button {
            background-color: #2A2F7A;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            border: 0;
            text-decoration: none;

            .upcoming-events-connect-button-icon {
                color: #fff;
                padding-top: 13px;
                padding-left: 10px;
                padding-right: 0.5rem;
                font-size: 20px;
                padding-right: 0.5rem;
                font-size: 20px;
            }
            
        }
        
    }
}