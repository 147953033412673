.pagination {
    display: flex;
    justify-content: center;
    padding-right: 0;
    margin-bottom: 80px;
    
    --bs-pagination-color: #2A2F7A;
    --bs-pagination-active-bg: #2A2F7A;
    --bs-pagination-active-border-color: #2A2F7A;
    --bs-pagination-focus-color: #2A2F7A;
    --bs-pagination-hover-color: #2A2F7A;
    --bs-pagination-focus-box-shadow: 0 0 0 0;

    @media screen and (max-width: 500px) {
        margin-bottom: 10px;
    }
}