.favorite {
    background-color: #fbfcfe;
    display: block;
    margin-bottom: 60px;
    padding: 75px 15px 10px 15px;

    .favorite-section {
        width: 100%;
        padding-top: 0.5rem;
        margin-bottom: 1rem;

        .favorite-section-title {
            font-weight: 400;
            font-size: 25px;
        }
    }
}