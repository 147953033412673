.notification-card {
    
    .notification-card-profile-photo {
        width: 40px;
        height: 40px;
        position: absolute;
        border-radius: 50%;
        left: 0px;
    }

    .notification-card-details {
        font-weight: 500;
        color: #adb5bd;
        font-size: 12px;
        line-height: 1.6;
    }
}