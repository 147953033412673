
.hire-section {
    margin-left: 15px;
    margin-right: 15px;

    .hire {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        background-color: #1E74FD;
        border: 0;
        border-radius: 0.3rem;
        margin-bottom: 1.5rem;
        padding: 15px;

        .title {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            font-size: 14px;
            letter-spacing: 1px;
        }

        .price {
            font-weight: 700;
            color: #fff;
            font-size: 44px;
        }

        .option {
            margin-bottom: 0.5rem;
            font-size: 10px;
            font-weight: 400;
            color: #fff;

            i {
                margin-right: 5px;
            }
        }

        .hire-button {
            width: 100% !important;
            color: #1E74FD !important;
            cursor: pointer;
            background-color: #fff !important;
        }

        .dropdown-arrow {
            top: 45px !important;
            z-index: 5 !important;
        }
    }
}