.topics-step
{
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .topics-step-title {
        font-weight: 400;
        display: block;
        font-size: 25px;
    }

    .tabs {
        position: relative;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        margin-bottom: 1.5rem;
        width: 100%;
        overflow: hidden;
        border-radius: 0.3rem;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
        height: auto;

        ul {
            justify-content: flex-start;
            height: 100%;
            align-items: center;

            li a {
                transition: all 0.4s ease;
                font-weight: 700;
                text-transform: uppercase;
                color: #adb5bd;
                display: inline-block;
                font-size: 12px;
                padding-bottom: 2.5rem;
                padding-top: 2.5rem;
            }

            li a.active {
                border-bottom: 2px #333 solid !important;
                color: #000;
            }

            .nav-item:last-child {
                margin-right: 3rem;
            }

            .nav-item:first-child {
                margin-left: 3rem;
            }

            .add-topic {
                color: #2A2F7A;
                font-size: 28px;
                cursor: pointer;
            }
        }

        .nav-tabs .nav-link {
            border: 0;
        }
    }

    .theme-step-buttons {
        display: flex;
        justify-content: space-between;

        .button {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            border: 1px solid transparent;
            font-size: 10px;
            border-radius: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 150px;
            height: 45px;
            margin-top: 10px;
        }

        .back-button {
            background-color: gray;
        }

        .save-button {
            background-color: #2A2F7A;
        }
    }
}