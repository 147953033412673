.contents {
    position: relative;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 1.5rem;
    width: 100%;
    overflow: hidden;
    border-radius: 0.3rem;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .published-title {
        padding-bottom: 0;
        min-height: 1px;
        padding: 1.25rem;
        //box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

        h2 {
            font-size: 25px;
            font-weight: 400;
        }
    }

    .published-items {
        min-height: 1px;
        padding: 1.25rem;
    }
}
