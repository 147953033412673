.dialog {
    .modal-content {
        margin-top: 20%;

        .header {
            position: relative;
            border-bottom: 1px solid #dddddd;
            background-color: #2A2F7A;
            color: white;

            .btn-close {
                border-bottom: 1px solid #dddddd;
                color: #2A2F7A;
                background-color: white;
                opacity: 1;
            }
        }


        .modal-footer {
            .close {
                background-color: gray;
                border-color: gray;
            }

            .submit {
                margin-right: 1rem;
                background-color: #2A2F7A;
                border-color: #2A2F7A;

                &:hover {
                    background-color: #2A2F7A;
                }
            }
        }
    }
}