.home {
    background-color: #fbfcfe;
    padding-top: 70px;
    min-height: 450px;

    .sidebar-left {
        display: flex;
        justify-content: center;
    }

    .sidebar-right {
        display: flex;
        justify-content: center;

        .container {
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .home {
        padding-top: 0px;
        padding-bottom: 50px;
    }
}
