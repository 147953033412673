.theme-container {
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto;
    flex: 1;
    width: 100%;
    position: relative;
    margin-top: 20px;
    background: white;

    .theme-container-steper {
        width: 100%;
        position: relative;
        z-index: 1;
        display: flex;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
        padding-top: 15px;
        
        .theme-steper-counter {
            counter-reset: step;
            margin: 30px auto;
            box-sizing: border-box;
            list-style: none;

            li {
                float: left;
                width: 170px;
                height: 30px;
                position: relative;
                text-align: center;
            }

            li:first-child:after {
                content: none;
            }
          
            li:last-child {
            content: none;
            }

            li.completed::before {
                border-color: #0366d6;
                background: #2A2F7A;
                color: #fff;
            }

            li.active::before {
                border-color: #0366d6;
                background: #fff;
                color: #2A2F7A;
            }

            li:before {
                content: counter(step);
                counter-increment: step;
                width: 30px;
                height: 30px;
                border: 3px solid #999;
                display: block;
                margin: 0 auto 10px auto;
                border-radius: 50%;
                line-height: 24px;
                background: #f2f2f2;
                color: #666;
                text-align: center;
            }

            li:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 3px;
                background: #999;
                top: 15px;
                left: -50%;
                z-index: -1;
            }

            li.completed::after {
                background: #0366d6;
            }

            li.active::after {
                background: #0366d6;
            }

            span {
                position: relative;
                text-align: center;
                top: -70px;
                color: #212121;
            }
        }

    }
}

