.relations-tab-content {
    .relation-users-container {
        border-radius: 5px;
        box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
        padding: 10px;
        margin-bottom: 20px;
        list-style: none;

        .relation-users-container-item {
            position: relative;
            min-height: 35px;
            padding-left: 0px;
            color: #999;
            z-index: 0;
            padding-top: 15px;
            padding-bottom: 15px;
            border-bottom: 1px dotted #ebebea;

            .relation-users-container-item-details {
                display: flex;
                flex-wrap: wrap;

                .relation-users-container-item-details-user {
                    display: flex;
                    flex-wrap: wrap;
                    padding-right: 5px;
                    padding-left: 15px;
                    text-decoration: none;

                    .user-profile-image {
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                    }

                    .user-information {
                        display: inline-grid;
                        white-space: nowrap;
                        padding-left: 10px;

                        .user-information-username {
                            color: #adb5bd;
                            font-size: 14px;
                        }

                        .user-information-profile {
                            font-weight: 600;
                            color: #adb5bd;
                            margin-left: 0;
                            font-size: 14px;
                        }
                    }
                }

                .relations-buttons {
                    display: flex;
                    justify-content: flex-end;

                    .button {
                        width: 150px;
                        height: 40px;
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 0.5rem;
                        margin-top: 0.5rem;
                        margin-left: 0.25rem;
                        display: inline-block;
                        text-align: center;
                        vertical-align: middle;
                        cursor: pointer;
                        user-select: none;
                        border: 1px solid transparent;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        border-radius: 30px;
                        font-size: 10px;
                        line-height: 24px;
                        letter-spacing: 1px;
                        background-color: #2A2F7A;
                        
                    }

                    .pending-button {
                        padding-bottom: 0.5rem;
                        padding-top: 0.5rem;
                        height: auto;
                        background-color: transparent;
                        border: 0;

                        .pending-button-image {
                            margin-right: 1rem;
                            font-size: 28px;
                            color: #2A2F7A;
                            border: 0;
                        }
                    }
                }
            }
        }

        .legend {
            font-weight: 600;
            color: #adb5bd;
            margin-left: 0;
            font-size: 12px;
        }
    }
}