.service-restrictions, .sale-digital-products-services, 
.withdrawal, .cancellation-particular-experiences {
    padding-left: 1.5rem;
    margin-left: 1.5rem;

    li {
        list-style: auto;
        color: #6c757d;
        padding-left: 0.5rem;
        padding-top: 0.5rem;
        margin-bottom: 0;
        font-size: 12px;
        line-height: 28px;
    }
}