.main-register {
    display: flex;
    min-height: 92vh;
    height: auto;
    
    .register-content {
        overflow: hidden;
        display: flex;

        .register-card {
            width: 100%;
            margin-top: 75px;
            overflow: hidden !important;
            background-color: transparent !important;
            padding-bottom: 15px;

            .register-card-body {
                text-align: left;
                border-radius: 0;
                flex: 1 1 auto;
                min-height: 1px;
                padding: 1.25rem;

                .register-card-body-title {
                    color: white;
                    font-weight: 700;
                    margin-bottom: 1.5rem;
                    font-size: 26px;
                }

                .accept-terms {
                    color: white;
                }

                .register-button {
                    display: flex;
                    justify-content: flex-end;

                    .submit-button {
                        font-weight: 700;
                        color: #fff;
                        text-transform: uppercase;
                        padding: 0.5rem;
                        border: 1px solid transparent;
                        background-color: #4EBCBC;
                        font-size: 14px;
                        border-radius: 10px;
                        letter-spacing: 1px;
                        width: 100%;
                        height: 60px;
                    }
                }

                .btn-login {
                    font-size: 14px;
                    line-height: 32px;
                    color: #adb5bd;
                    font-weight: 500;

                    a {
                        color: white;
                        font-weight: 700;
                        text-decoration: none;
                        margin-left: 15px;;
                    }
                }
            }
        }
    }
}

.register-image {
    background: url(../../../general/images/login_background.png) no-repeat right center fixed;
    background-size: cover;
}

.terms-and-conditions-link {
    padding-left: 25px;
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
}