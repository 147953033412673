.view-content {
    background-color: #fbfcfe;
    padding-top: 90px;
    padding-right: 20px;
    margin-bottom: 80px;  
    margin-right: 20px;
}

@media screen and (max-width: 500px) {
    .view-content {
        padding-top: 0px;
    }
}
