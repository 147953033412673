.payment-section {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;

    button {
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
        padding: 0.5rem;
        margin-top: 0.5rem;
        margin-left: 0.25rem;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        border: 1px solid transparent;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-radius: 30px;
        font-size: 10px;
        line-height: 24px;
        letter-spacing: 1px;
        width: 100%;
        
        text-decoration: none;
    }

    .pay-button {
        background-color: #2A2F7A;
    }

    .cancel-button {
        background-color: gray;
    }

    button:disabled {
        background-color: #eaeaea;
        border: 1px solid #ccc;
        color: #666;
        cursor: default;
    }
}
