.my-profile {
    .my-purchases {
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
        padding: 1.25rem;

        .my-purchases-history {
            margin-top: 1.5rem;
            font-weight: 700;
            margin-bottom: 1rem;
            font-size: 1.125rem;
        }

        .my-purchases-history-grid {

        
            .my-purchases-history-grid-date-column {
                display: flex;
                justify-content: center;
                margin-top: 20px;

                .my-purchases-history-grid-date-column-header {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    font-weight: 700;
                    font-size: 14px;
                }
            }

            .my-purchases-history-grid-details-column {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                margin-top: 10px;
                position: relative;
    
                hr {
                    margin-top: 1rem;
                    margin-bottom: 1rem;
                    border: 0;
                    border-top: 1px solid rgba(0, 0, 0, 0.1);
                }
    
                .my-purchases-history-grid-details-column-container {
                    margin-left: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
    
                    .my-purchases-history-grid-details-column-container-header {
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                        font-weight: 700;
                        font-size: 14px;
                    }
    
                    .my-purchases-history-grid-details-column-container-information-section {
                        display: flex;
                        font-size: 12px;
                        margin-top: 2px;
                        opacity: .7;
                        overflow: hidden;
                        white-space: pre;
    
                        .my-purchases-history-grid-details-column-container-information-section-item {
                            display: flex;
                            margin-right: 15px;
    
                            .my-purchases-history-grid-details-column-container-information-section-item-value {
                                font-weight: 600;
                                margin-left: 3px;
                            }
    
                            a {
                                color: black;
                                text-decoration: none;
                            }
                        }
                    }
                }
    
                
            }
        }
    }
}