.wrap-error {
    background-color: #fcedec;
    border-top: 1px solid #f8b3b2;
    border-bottom: 1px solid #f8b3b2;
    border-right: 1px solid #f8b3b2;
    border-left: 4px solid #e34f45;

    .message-icon {
        color: #e34f45;
    }
}

.wrap-success {
    background-color: #ebf7f1;
    border-top: 1px solid #99d6b9;
    border-bottom: 1px solid #99d6b9;
    border-right: 1px solid #99d6b9;
    border-left: 4px solid #33ad73;

    .message-icon {
        color: #33ad73;
    }
}

.wrap-message {
    display: flex;
    border-radius: 3px;
    align-items: center;
    justify-content: flex-start;
    padding: 18px;
    position: relative;
    overflow: hidden;
    margin-top: 10px;

    .message-icon {
        width: 22px;
        height: 20px;
        position: relative;
        display: table;
    }

    .content-message {
        padding-left: 12px;
        width: 100%;

        p {
            margin-top: 0px;
            margin-bottom: 0px;
            font-size: 1rem;
            line-height: 24px;
            color: #333;
        }
    }
}