.publications-list {

    .publication-item {
        border-radius: 5px;
        box-shadow: 0 1px 5px rgb(0 0 0 / 25%);
        padding: 10px;
        margin-bottom: 20px;
        background-color: white;
        list-style: none;
        margin-top: 5px;

        .publication-item-header {
            position: relative;
            min-height: 35px;
            padding-left: 0px;
            color: #999;
            z-index: 0;

            .user {
                display: flex;

                .user-photo {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                }
            }

            .details {
                padding-left: 15px;
                display: inline-grid;
                padding-bottom: 10px;

                span {
                    line-height: 12pt;
                    font-weight: 600;
                    font-size: 14px;
                    color: #999;
                }

                .username {
                    font-size: 13.6px;
                    font-weight: bolder;
                }

                .activity-type {
                    font-size: 14px;
                    font-weight: 600;
                }

                .time {
                    font-size: 12px;
                    font-weight: 600;
                    color: #adb5bd;
                    line-height: 12pt;
                }
            }

            .publication-buttons-section {
                margin-left: auto;

                i {
                    color: #2A2F7A;
                }

                button {
                    border: 0px;
                    background-color: transparent;
                    font-size: 15pt;
                }

                .pinned {
                    border: 0px;
                    background-color: transparent;
                    font-size: 15pt;
                }
            }
        }

        .publication-item-content {
            margin-top: 5px;
            font-size: 11pt;

            .pagination {
                display: flex;
                justify-content: center;
                padding-right: 0;
            
                --bs-pagination-color: #2A2F7A;
                --bs-pagination-active-bg: #2A2F7A;
                --bs-pagination-active-border-color: #2A2F7A;
                --bs-pagination-focus-color: #2A2F7A;
                --bs-pagination-hover-color: #2A2F7A;
                --bs-pagination-focus-box-shadow: 0 0 0 0;
                margin-bottom: 0px;
            }
              
            .items {
            margin-bottom: 1rem;
            }
                
            .my-slide {
                display: flex;
                height: 300px;
                justify-content: center;
                padding-top: 20px;
                padding-bottom: 20px;

                img {
                    max-width: 800px;
                }

                video {
                }

                @media screen and (max-width: 500px) {
                    img {
                        width: 300px;
                    }
        
                    video {
                        width: 340px !important;
                    }
                }
            }

            .review-publication {
                margin-top: 20px;
                justify-content: center;
                display: flex;
                font-weight: 700;
            }

            .publication-image {
                margin: 0;
                list-style: none;
                padding: 0;
                padding-top: 13px;

                .publication-image-panel {
                    display: flex;
                    justify-content: center;
                }


                li img {
                    width: 625px;
                    height: 300px;
                }

                @media screen and (max-width: 500px) {
                    li img {
                        width: 365px;
                    }
                }
            }
        }

        .publication-item-footer {
            display: -ms-flexbox;
            display: -webkit-box;
            display: flex;
            margin-top: 23px;
            padding: 8px 8px 0;
            color: #999;
            border-top: 1px dotted #ebebea;

            .publication-item-footer-buttons {
                display: flex;
                justify-content: flex-end;
                width: 100%;

                i {
                    color: lightgrey;
                }

                button {
                    //margin-left: auto;
                    border: 0px;
                    background-color: transparent;
                    font-size: 15pt;
                }

                .publication-item-footer-buttons-active {
                    color: #2A2F7A;
                }
            }
        }

        .publication-item-like {
            display: -webkit-box;
            display: flex;
            margin-top: 23px;
            padding: 13px 8px 0;
            color: #999;
            font-size: 14px;

            button {
                margin-left: auto;
                background-color: transparent;
                border: 0;
                color: inherit;
                font-weight: 600;
            }

            .label {
                font-weight: 700;
                margin-left: 5px;
            }
        }
    }
}