.information {
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    margin-bottom: 50px;

    .sobre-mi {
        margin-bottom: 0px;
        background-color: #fff;
        border-radius: 5px;
        padding-top: 1px;
        padding-bottom: 5px;
        padding-left: 25px;
        padding-right: 25px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        min-height: 150px;
        margin-right: 10px;

        h4 {
            font-weight: 700;
            color: #343a40;
            margin-top: 1.5rem;
            font-size: 14px;
        }

        p {
            white-space: pre-line;
            font-weight: 600;
            color: #adb5bd;
            padding-top: 1rem;
            padding-left: 0;
            margin-bottom: 0;
            font-size: 12px;
            margin-top: 0;
        }
    }

    .user {
        margin-bottom: 0px;
        background-color: #fff;
        border-radius: 5px;
        padding-top: 1px;
        padding-bottom: 5px;
        padding-left: 25px;
        padding-right: 25px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        min-height: 150px;
        margin-right: 10px;

        h4 {
            font-weight: 700;
            color: #343a40;
            margin-top: 1.5rem;
            font-size: 14px;
        }

        .country {
            margin-top: 1rem;
            display: flex;

            .flag {
                border-radius: 50%;
                width: 35px;
                height: 35px;
            }

            .time-zone {
                font-weight: 600;
                padding-left: 0.5rem;
                margin-top: 0.5rem;
                font-size: 14px;
                color: #696969;
            }
        }

        .join-edowie {
            font-weight: 400;
        }

        .social-networks {
            padding-top: 10px;

            .icon {
                margin-right: 10px;
                font-size: 32px;
                line-height: 1;
                color: #2A2F7A;
            }
        }
    }
}
