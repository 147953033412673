.my-profile {
    .edit-account {
        overflow: hidden;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

        .form {
            padding: 1.5rem;
            margin-bottom: 0;
            width: 100%;
            border: 0;
            flex: 1 1 auto;
            min-height: 1px;

            .file-card {
                padding-top: 10px;
                padding-bottom: 10px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
            }

            .save-button {
                display: flex;
                justify-content: flex-end;
                margin-top: 25px;
            }
        }
    }
}
