.calendar {


    .fc {
        .fc-button-primary {
            background-color: #2A2F7A;
            border-color: #2A2F7A;
            color: white;
        }

        .fc-prev-button {
            margin-left: 10px;
        }
    }
}

.fc-past-event {
    border: 2px solid lightgray;
    background-color: lightgray;
    color: black !important;
    width: 100%;
    height: auto;
    padding-left: 5px;
}

.fc-reserved-event {
    border: 1px solid red;
    background-color: red;
    color: #fff;
    width: 100%;
    height: 100%;
    padding-left: 5px;
}

.fc-available-event {
    border: 1px solid mediumseagreen;
    background-color: mediumseagreen;
    color: #fff;
    width: 100%;
    height: 100%;
    padding-left: 5px;
    cursor: pointer;
}

.fc-v-event {
    background-color: transparent;
    border: 0;
    display: block;
}

.fc-event-selected {
    border: 1px solid blue !important;
    background-color: blue !important;
    color: white !important;
}

.fc-col-header-cell {
    background-color: #2A2F7A;

    a {
        text-decoration: none;
        color: #fff;
    }
}

.fc .fc-view-harness {
    height: 555px !important;
}