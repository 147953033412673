.file-list {
    padding-inline-start: 0px;

    li {
        list-style: none;
        margin: 10px;
        background-color: #e8f1fa;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 5px 10px;

        svg {
            color: #2A2F7A;

            &:first-child {
                font-size: 1.2em;
                margin-right: 0.8em;
            }
        }

        p {
            flex: 1;
            font-size: 0.9rem;
            margin-bottom: 0px;
        }

        .actions {
            justify-self: flex-end;
            cursor: pointer;

            .fa-spinner {
                font-size: 1.2em;
            }
        }
    }
}
