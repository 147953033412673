.chat-message {

    .chat-message-date {
        text-align: center;
        width: 100%;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
    }

    .chat-message-base {
        margin-top: 10px;
        min-height: 40px;
        border-radius: 8px;
        padding-left: 5px;
        padding-top: 5px;
        justify-content: space-between;
        display: flex ;
        padding-right: 5px;
    }

    .chat-message-origin {
        border: 1px solid lightgreen;
        background-color: lightgreen;
        margin-right: 1rem;
    }

    .chat-message-destination {
        border: 1px solid lightgray;
        background-color: lightgray;
        margin-left: 1rem;
    }
    
}