.modal-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: #000000, $alpha: 0.35);
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    z-index: 1000;

    .modal {
        background-color: white;
        width: calc(15rem + 15vw);
        height: auto;
        border-radius: 10px;
        backdrop-filter: blur(5px);
        box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
        transform: translateY(-600px);
        transition: all 0.4s ease;
        display: block;
        margin-left: 35%;
        margin-top: 10%;
        padding-bottom: 10px;

        &_header {
            position: relative;
            border-bottom: 1px solid #dddddd;
            background-color: #2A2F7A;
            color: white;

            &-title {
                text-align: center;
                font-size: 20pt;
                padding-top: 6px;
            }

            .close {
                position: absolute;
                top: 0;
                right: 0;
                background: transparent;
                border: 0px;

                i {
                    color: white;
                    font-size: 23pt;
                }
            }
        }

        &_content {
            border-bottom: 1px solid #dddddd;
            padding: 1rem 1rem;
        }

        &_footer {
            padding-bottom: 0;
            padding-top: 10px;
            padding-right: 10px;

            button {
                float: right;
                padding: 0.5rem;
                border-radius: 8px;
                color: white;
                border: 1px white solid;
            }

            .close {
                background-color: gray;
            }

            .submit {
                margin-right: 1rem;
                background-color: #2A2F7A;


                &:hover {
                    background-color: #2A2F7A;
                }
            }

            .modal-close {
                background-color: transparent;
                font-weight: 600;

                &:hover {
                    color: rgba(54, 67, 72, 0.8);
                }
            }
        }
    }
}

.modal-container.show {
    visibility: visible;
    opacity: 1;

    .modal {
        transform: translateY(0);
    }
}
