.qualification-for-star {
    .star-item {
        margin-top: 5px;

        .star {
            width: 12px;
        }

        .star-number {
            font-weight: 600;
            color: #6c757d;
            margin-left: 0.25rem;
            display: inline;
            font-size: 14px;
        }

        .progress-bar {
            position: relative;
            background-color: #eee;
            border-radius: 10px;
            box-sizing: border-box;
            height: 5px;
            margin-top: 7px;
            width: 100%;

            .bar-percentage {
                background-color: #aaa;
                border-radius: 10px;
                height: 5px;
                padding: 0;
                width: 0;
                transition: all 0.4s ease;
            }
        }

        .star-percentage {
            font-weight: 600;
            color: #343a40;
            font-size: 12px;
        }
    }
}