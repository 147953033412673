.content, .view-content-tabs, .enroll-group-experience {
    .user-section {
        width: 100%;
        padding-bottom: 1rem;
        margin-top: 1.5rem;
        display: block;
        border-radius: 0.3rem;
        border: 0;
        background-color: transparent;
        position: relative;
        min-width: 0;
        word-wrap: break-word;
        background-clip: border-box;
        margin-left: 20px;

        .profile-image {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            vertical-align: middle;
            border-style: none;
        }

        .username {
            font-weight: 700;
            color: #212529;
            margin-left: 5px;
            display: inline-block;
            font-size: 12px !important;
        }

        .separator {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 8px;
            line-height: 25px;
            text-align: center;
            background-color: #eee;
        }

        .level {
            font-weight: 600;
            color: #adb5bd;
            margin-left: 0.25rem;
            display: inline-block;
            font-size: 12px;
        }

        .relation-button {
            font-weight: 700;
            color: #1E74FD;
            margin-left: 0;
            display: inline-block;
            font-size: 12px;
            border: 0;
            background-color: transparent;
        }
    }
}
