.general-conditions-step
{
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .general-conditions-step-title {
        font-weight: 400;
        display: block;
        font-size: 25px;
    }

    .general-conditions-step-form {
        padding-top: 30px;

        .general-conditions-step-form-padding-0 {
            padding: 0;
        }

        .save-button {
            display: flex;
            justify-content: flex-end;
        }
    }
}