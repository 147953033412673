.service-promocodes {
    position: relative;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 1.5rem;
    width: 100%;
    overflow: hidden;
    border-radius: 0.3rem;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    display: block;
    border: 0;

    .service-promocodes-title {
        padding-bottom: 0;
        margin-bottom: 1rem;
        min-height: 1px;
        padding: 1.25rem;

        h2 {
            font-weight: 400;
            display: block;
            font-size: 25px;
        }
    }

    .service-promocodes-container {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 1px;
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        .service-promocodes-container-sub-title {
            margin-bottom: 1rem;
            margin-top: 0;
        }
    }
}