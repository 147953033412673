.landing-page {

    .blog-preview-section {
        background-color: #f7c245;
        padding-bottom: 1rem;
        padding-top: 1rem;
        display: block;

        .blog-preview-section-container {
            margin-bottom: 3rem;
            margin-top: 3rem;
            margin-right: 13%;
            margin-left: 13%;

            .button {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
                padding: 0.5rem 1rem;
                font-size: 1.25rem;
                line-height: 1.5;
                border-radius: 0.3rem;
                text-decoration: none;
                display: inline-block;
                font-weight: 400;
                text-align: center;
                vertical-align: middle;
                cursor: pointer;
                user-select: none;
                border: 1px solid transparent;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                --bs-btn-padding-y: 0.5rem;
                --bs-btn-padding-x: 1rem;
                --bs-btn-font-size: 1.25rem;
            }

            .blog-preview-section-container-header {
                justify-content: center;
                --bs-gutter-x: 3rem;

                .header-container {
                    text-align: center;

                    .header-title {
                        font-size: 1.25rem;
                        font-weight: bolder;
                    }

                    .header-description {
                        margin-bottom: 3rem;
                        font-size: 1.25rem;
                        font-weight: 400;
                        line-height: 35px;
                    }
                }
            }

            .blog-preview-section-container-body {

                .feature-section {
                    margin-bottom: 3rem;

                    .feature-card {
                        height: 100%;
                        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
                        border: 0;
                        position: relative;
                        display: flex;
                        flex-direction: column;
                        min-width: 0;
                        word-wrap: break-word;
                        background-color: #fff;
                        border-radius: 0.25rem;
                        width: 100%;
                        margin-left: 0px;
                        margin-right: 0px;

                        .card-img-top {
                            border-top-left-radius: calc(0.25rem - 1px);
                            border-top-right-radius: calc(0.25rem - 1px);
                        }

                        .feature-card-body {
                            padding: 1.5rem;
                            flex: 1 1 auto;
                            min-height: 1px;

                            .badge {
                                margin-bottom: 0.5rem;
                                border-radius: 50rem;
                                background-color: #1E74FD;
                                display: inline-block;
                                padding: 0.25em 0.4em;
                                font-size: 75%;
                                font-weight: 700;
                                line-height: 1;
                                text-align: center;
                                white-space: nowrap;
                                vertical-align: baseline;
                                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                                background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
                            }

                            .feature-card-body-link {
                                text-decoration: none;
                                color: #212529;
                                
                                .link-title {
                                    font-weight: 400;
                                    margin-bottom: 1rem;
                                    font-size: 0.875rem;
                                    line-height: 1.2;
                                    color: #111;
                                }
                            }

                            .link-description {
                                margin-bottom: 0;
                                font-size: 0.875rem;
                            }
                        }

                        .feature-card-button {
                            padding-top: 35px;
                            justify-content: center;
                            display: flex;
                            gap: 1rem;

                            .explore-button {
                                --bs-btn-border-radius: 0.5rem;--bs-btn-bg: #1313cb;
                                --bs-btn-border-color: #1313cb;
                                background-color: #2A2F7A;
                                border: 1px solid white;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .register-button-container {
                padding-top: 35px;
                justify-content: center;
                display: flex;
                gap: 1rem;

                .register-button {
                    color: #fff;
                    border-color: #1E74FD;
                    background-color: #e83088;
                }
            }
        }
    }
}