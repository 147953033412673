.manage-service-donations {
    position: relative;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 1.5rem;
    width: 100%;
    overflow: hidden;
    border-radius: 0.3rem;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    padding: 1.5rem;
    display: block;
    border: 0;

    .donations-title {
        text-align: center;
        font-weight: 400;
        display: block;
        font-size: 25px;
    }

    .donations-activate-deactivate {
        justify-content: center;
        width: 100%;
        display: flex;

        .button {
            width: 120px;
            cursor: pointer;
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            margin-top: 1.5rem;
            margin-left: 0.25rem;
            display: inline-block;
            border: 1px solid transparent;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border-radius: 30px;
            font-size: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            overflow: visible;
        }

        .donations-deactivate-button {
            background-color: red;
        }

        .donations-activate-button {
            background-color: #2A2F7A;
        }
    }
}