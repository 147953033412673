.content-item {

    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 0;
        border-radius: 0.3rem;
        overflow: hidden;
        margin-right: 0.25rem;
        margin-bottom: 1.5rem;
        padding: 0;
        box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
        width: 300px;

        .card-image {
            overflow: hidden;
            margin-bottom: 1rem;
            width: 100%;

            a {
                position: relative;
                display: block;

                img {
                    height: 200px;
                    width: 100%;
                    vertical-align: middle;
                    border-style: none;
                }
            }
        }

        .card-details {
            padding-top: 0;
            flex: 1 1 auto;
            min-height: 1px;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            padding-bottom: 1.25rem;

            .tag {
                font-weight: 700;
                text-transform: uppercase;
                padding-left: 1rem;
                padding-right: 1rem;
                margin-right: 0.25rem;
                display: inline-block;
                border-radius: 0.3rem;
                font-size: 10px;
                line-height: 32px;
                letter-spacing: 0.6px;
            }

            .course-tag {
                background-color: #A9D0F5;
                border-color: #ffe1c5;
                color: #0000FF;
            }

            .resource-tag {
                background-color: #ffead6;
                border-color: #ffe1c5;
                color: #FE9431;
            }

            .teacher-profile-price-card-section {
                display: flex;
                justify-content: flex-end;
                padding-top: 10px;

                .teacher-profile-price-card {
                    text-align: center;

                    .teacher-profile-free {
                        color: #10d876;
                        font-size: 15px;
                        line-height: 30px;
                        margin-bottom: 0;
                        text-transform: none;
                        display: inherit;
                        height: auto;
                    }

                    .teacher-profile-price-without-discount{
                        font-size: 14px;
                        line-height: 24px;
                        color: #2A2F7A;
                        text-decoration: line-through;
                        font-weight: 700;
                    }

                    .teacher-profile-price-with-discount {
                        font-size: 15px;
                        line-height: 30px;
                        margin-bottom: 0;
                        text-transform: none;
                        font-weight: 700;
                        display: inherit;
                        height: auto;
                        color: #212121;
                    }
                }
            }

            h4 {
                font-weight: 700;
                margin-top: 1rem;
                font-size: 16px;
                line-height: 28px;

                a {
                    color: #212529;
                    text-decoration: none;
                    outline: none;
                }
            }

            h6 {
                font-weight: 600;
                color: #adb5bd;
                margin-top: 0.5rem;
                margin-left: 0;
                font-size: 12px;
            }

            .qualification {
                display: flex;
                justify-content: flex-start;

                .stars {
                    display: flex;
                    height: 12px;
                    text-align: left;
                    margin-right: 0.5rem;
                    margin-top: 0.25rem;

                    .star {
                        width: 12px;
                    }
                }

                .reviews {
                    margin-bottom: 0;
                    font-weight: 600;
                    color: #adb5bd;
                    font-size: 12px;
                    padding-top: 2px;

                    p:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}
