.enroll-group-experience {
    .header {
        margin-bottom: 0;
        overflow: hidden;
        border-radius: 0.3rem;
        border: 0;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        margin-left: 20px;

        .card {
            padding: 1.5rem;
            flex: 1 1 auto;
            min-height: 1px;
            background: rgba(0, 0, 0, 0.8);

            .tags-favorites {
                display: flex;
                justify-content: space-between;
                margin-top: 20px;

                .tag {
                    font-weight: 700;
                    text-transform: uppercase;
                    padding-left: 1rem;
                    padding-right: 1rem;
                    margin-bottom: 0.25rem;
                    margin-right: 0.25rem;
                    display: inline-block;
                    border-radius: 0.3rem;
                    font-size: 10px;
                    line-height: 32px;
                    letter-spacing: 0.6px;
                }

                .group-experience-tag {
                    background-color: #facccc;
                    border-color: #f8b8b8;
                    color: #E50202;
                }

                .favorites-group-experience {
                    .button {
                        margin-left: 0.5rem;
                        margin-bottom: 0.5rem;
                        display: inline-block;
                        border-radius: 0.3rem;
                        width: 50px;
                        height: 50px;
                        line-height: 50px;
                        text-align: center;
                        border: 0;

                        i {
                            line-height: 50px;
                            color: #fff;
                            font-size: 20px;
                        }
                    }

                    .add-favorties {
                        background-color: #E50202;
                    }

                    .delete-favorities {
                        background-color: #009378;
                    }
                }
            }

            .title {
                font-weight: 700;
                color: #fff;
                margin-top: 0.5rem;
                margin-bottom: 0.25rem;
                display: block;
                font-size: 25px;
                line-height: 1.6;
            }

            .description {
                font-weight: 500;
                color: #f8f9fa;
                margin-top: 1rem;
                font-size: 14px;
                line-height: 30px;
            }

            .labels {
                font-weight: 600;
                color: #adb5bd;
                margin-left: 0;
                display: inline-block;
                font-size: 12px;
            }

            .show-ratings {
                border: 0;
                background: transparent;
                padding-left: 0;
                padding-top: 10px;

                .qualification {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 0.5rem;
                    padding-top: 5px;

                    .stars {
                        display: flex;
                        height: 15px;
                        text-align: left;
                        margin-right: 0.5rem;
                        margin-top: 0.25rem;

                        .star {
                            width: 15px;
                        }
                    }

                    .reviews {
                        font-weight: 600;
                        color: #adb5bd;
                        font-size: 12px;
                        line-height: 1.4;
                        margin-bottom: 0;
                        margin-top: 0.25rem;

                        p:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .enrolled-by {
                font-weight: 600;
                color: #adb5bd;
                display: inline-block;
                font-size: 12px;
            }
        }
    }
}
