.requirements-section {
    width: 100%;
    margin-left: 20px;
    padding: 1.5rem;
    margin-top: 1.5rem;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    color: #08703d;
    background-color: #cff7e4;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);
    margin-bottom: 1.5rem;

    h2 {
        font-weight: 700;
        color: #10d876;
        padding-left: 0.25rem;
        margin-bottom: 1.5rem;
        margin-top: 0.25rem;
        font-size: 20px;
    }

    p {
        white-space: pre-line;
    }
}
