.landing-page {

    .header {
        background-color: #1313cb;
        position: relative;
        display: block;

        .header-body {
            margin-right: 13%;
            margin-left: 13%;

            .header-body-container {
                align-items: center;
                justify-content: center;
                --bs-gutter-x: 3rem;

                .header-body-container-title-section {
                    text-align: center;
                    margin-bottom: 3rem;
                    margin-top: 3rem;

                    .title {
                        margin-bottom: 2rem;
                        color: white;
                        font-weight: bolder;
                        font-size: 3rem;
                        line-height: 1.2;
                    }

                    .subtitle {
                        color: rgba(255, 255, 255, 0.5);
                        margin-bottom: 1.5rem;
                        font-size: 1.25rem;
                        font-weight: 400;
                    }
                }

                .sale-buy-content-buttons-section {
                    justify-content: center;
                    display: flex;
                    gap: 1rem;

                    .content-button {
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                        display: inline-block;
                        font-weight: 400;
                        text-align: center;
                        vertical-align: middle;
                        cursor: pointer;
                        user-select: none;
                        border: 1px solid transparent;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        --bs-btn-padding-y: 0.5rem;
                        --bs-btn-padding-x: 1rem;
                        --bs-btn-font-size: 1.25rem;
                        --bs-btn-border-radius: 0.5rem;
                        text-decoration: none;
                        padding: 0.5rem 1rem;
                        font-size: 1.25rem;
                        line-height: 1.5;
                        border-radius: 0.3rem;
                    }

                    .sale-content-button {
                        color: #fff;
                        border-color: #1E74FD;
                        background-color: #e83088;
                    }

                    .buy-content-button {
                        background-color: #2A2F7A;
                        border: 1px solid white;
                        color: white;
                    }
                }

                .login-button-section {
                    padding-top: 1.5rem;
                    justify-content: center;
                    display: flex;
                    gap: 1rem;

                    .login-button {
                        text-decoration: none;
                        padding-left: 1.5rem;
                        padding-right: 1.5rem;
                        padding: 0.5rem 1rem;
                        font-size: 1.25rem;
                        line-height: 1.5;
                        border-radius: 0.3rem;
                        color: #fff;
                        background-color: #e83088;
                        border-color: #1E74FD;
                        display: inline-block;
                        font-weight: 400;
                        text-align: center;
                        vertical-align: middle;
                        cursor: pointer;
                        user-select: none;
                        border: 1px solid transparent;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                        --bs-btn-padding-y: 0.5rem;
                        --bs-btn-padding-x: 1rem;
                        --bs-btn-font-size: 1.25rem;
                        --bs-btn-border-radius: 0.5rem;
                    }
                }

                .right-image {
                    border-radius: 10px;
                    text-align: center;
                    display: block;

                    img {
                        margin-bottom: 3rem;
                        margin-top: 3rem;
                        max-width: 100%;
                        height: auto;
                        border-radius: 0.5rem !important;
                    }
                }
            }
        }
    }
}