.contents {
    padding: 1.5rem;
    width: 100%;
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .title {
        padding-bottom: 0;
        margin-bottom: 1rem;
        min-height: 1px;
        padding: 1.25rem;

        h2 {
            font-weight: 400;
            display: block;
            font-size: 25px;
        }
    }

    .contents-container {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 1px;
        padding-left: 1.25rem;
        padding-right: 1.25rem;

        .sub-title {
            margin-bottom: 1rem;
            margin-top: 0;
        }
    }

    .publish-button {
        text-align: right;

        a {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            margin-top: 0.5rem;
            margin-left: 0.25rem;
            display: inline-block;
            text-align: center;
            vertical-align: middle;
            cursor: pointer;
            user-select: none;
            border: 1px solid transparent;
            transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border-radius: 30px;
            font-size: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 100px;
            background-color: #2A2F7A;
            text-decoration: none;
        }
    }
}
