.notes {
    overflow: hidden;
    display: block;
    border-radius: 0.3rem;
    border: 0;
    position: relative;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.05);

    .form {
        padding: 1.5rem;
        margin-bottom: 0;
        width: 100%;
        border: 0;
        flex: 1 1 auto;
        min-height: 1px;

        .file-card {
            padding-top: 10px;
            padding-bottom: 10px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        }

        .buttons {
            display: flex;
            justify-content: space-between;
            margin-top: 25px;

            .close {
                padding: 0.5rem;
                border-radius: 8px;
                color: white;
                border: 1px white solid;
                background-color: gray;
                border-radius: 10px;
                line-height: 24px;
                letter-spacing: 1px;
                width: 100px;
                height: 45px;
                margin-top: 10px;

                i {
                    color: white;
                    font-size: 23pt;
                }
            }
        }
    }

    .unselected-file {
        margin-top: 30px;
        margin-bottom: 30px;
        margin-left: 20px;
    }
}

.file-viewer-notes {
    .file-viewer-notes-header {
        background-color: #2A2F7A;
        padding: 1.5rem;
        width: 100%;
        display: flex;
        border-radius: 0.3rem;
        border: 0 !important;

        .file-viewer-notes-header-title {
            font-weight: 600;
            color: #fff;
            font-size: 18px;
        }
    }

    .file-viewer-notes-details {
        border-left: 1px solid #d1d7dc;
        border-right: 1px solid #d1d7dc;
        padding: 1.1rem;
        border-bottom: 1px solid #d1d7dc;
        border-radius: 5px;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            max-width: none;

            .item {
                color: #1c1d1f;
                padding: 0.5rem 0;
                display: flex;
                align-items: flex-start;
                width: 100%;
                height: auto;
                text-align: left;
                letter-spacing: normal;
                white-space: normal;
                
                button {
                    color: #1c1d1f;
                    text-decoration: none;
                    border: 0;
                    background-color: transparent;

                    i {
                        margin-right: 1rem;
                        font-size: 20px;
                    }
                }
                
                .item-title-content {
                    max-width: 180px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .notes {
                    padding-left: 5px;
                    font-weight: 600;
                    cursor: pointer;
                    padding-top: 0px;
                    border: 0;
                    background-color: transparent;
                    font-size: 13px;
                }

                .separator {
                    flex: 1 1 0%;
                }

                span {
                    font-size: 13px;
                }

                .delete-notes {
                    font-size: 20px;
                    margin-left: 10px;
                }

                .download-file {
                    color: #1c1d1f;
                    padding-left: 1rem;
                    height: auto;
                    text-decoration: none;
                    cursor: pointer;

                    i {
                        margin-right: 1rem;
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .file-viewer-notes-add-notes {
        margin-top: 10px;
        display: flex;
        justify-content: flex-center;
        margin-right: 10px;
        width: 100%;

        button {
            font-weight: 700;
            color: #fff;
            text-transform: uppercase;
            padding: 0.5rem;
            border: 1px solid transparent;
            background-color: #2A2F7A;
            font-size: 10px;
            border-radius: 10px;
            line-height: 24px;
            letter-spacing: 1px;
            width: 100%;
            height: 45px;
            margin-top: 10px;
        }

        &:hover {
            button {
                background-color: #2A2F7A;
                color: #fff;
            }
        }
    }
}